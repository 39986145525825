import { memo, useMemo } from "react";
import { classnames } from "Utils/Classnames.Util";
import { MAX_INNER_CONTENT_WIDTH } from "Constants/Common.Constant";

import ImageComponent from "./Image.Component";
import ContentComponent from "./Content.Component";

// TODO: refactor later
const CashFlowItem = ({
  item,
  itemWidth,
  onToggle,
  expandingId,
}: {
  item: { id: number; title: string; content: string; image: any };
  itemWidth: number;
  onToggle: (id: number) => void;
  expandingId: any;
}) => {
  const isExpanding = useMemo(
    () => expandingId === item.id,
    [expandingId, item.id]
  );
  // list width when expanding - gaps of 3 items - 3 normal item width
  const plusWidth = useMemo(
    () => MAX_INNER_CONTENT_WIDTH - 20 * 2 - itemWidth * 3,
    [itemWidth]
  );
  const calculatedWidth = useMemo(
    () => (isExpanding ? itemWidth + plusWidth : itemWidth),
    [isExpanding, itemWidth, plusWidth]
  );
  const calculateHeight = useMemo(() => itemWidth, [itemWidth]);

  return (
    <div
      className={classnames(
        "relative flex cursor-pointer justify-end overflow-hidden transition-all duration-500",
        {
          "flex justify-end rounded-[40px] bg-white-slate p-5": isExpanding,
        }
      )}
      style={{
        width: calculatedWidth,
        height: calculateHeight,
      }}
      suppressHydrationWarning={true}
      onClick={(event) => {
        event.preventDefault();
        onToggle(item.id);
      }}
    >
      <ContentComponent
        id={item.id}
        title={item.title}
        content={item.content}
        isExpanding={isExpanding}
        onToggle={onToggle}
      />
      <ImageComponent
        itemWidth={itemWidth}
        image={item.image}
        isExpanding={isExpanding}
      />
    </div>
  );
};

export default memo(CashFlowItem);
