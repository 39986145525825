import { SectionWrap } from "Components/Layouts";
import { DEVELOPER_API_REF } from "Constants/URL.Constant";
import { Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";
import { NextLink } from "Components/Lib/Next";

import TitleComponent from "./Title.Component";
import PlatFormList from "./PlatformList";

const Section3 = () => {
  return (
    <SectionWrap
      containerProps={{
        className: "pt-12 pb-8",
      }}
      contentContainerProps={{
        className: "flex flex-col items-center",
      }}
      className="flex justify-center"
    >
      <TitleComponent />
      <PlatFormList />

      <Typography.Body className="mt-14 text-center">
        {localesText("buy_to_earn.or_tailor_your")}{" "}
        <strong className="text-clever-link underline underline-offset-[5px]">
          <NextLink href={DEVELOPER_API_REF} target="_blank">
            {localesText("buy_to_earn.hello_clever_api")}
          </NextLink>
        </strong>
      </Typography.Body>
    </SectionWrap>
  );
};

export default Section3;
