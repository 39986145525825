import { NormalImage } from "Components/Lib";
import { Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

import GeometricFive from "Assets/Images/Others/geometric_five.png";
import HelloClever from "Assets/Images/Others/partnership_tyro.png";

export default function InstantCashback() {
  return (
    <div className="flex flex-col gap-6 md:gap-12 flex-1 justify-center rounded-[40px] bg-clever-light-green p-6 md:p-10 relative overflow-hidden">
      <Typography.Heading1 className="z-10">
        {localesText("tyro.your_instant_cashback")}
      </Typography.Heading1>{" "}
      <NormalImage src={HelloClever.src} />
      <img
        src={GeometricFive.src}
        height={60}
        alt="customer-avatar"
        className="absolute right-0 bottom-0 w-[120px] md:w-[200px]"
      />
    </div>
  );
}
