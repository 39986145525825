import { SectionWrap } from "Components/Layouts";

import TitleComponent from "./Title.Component";
import PricingList from "./PricingList";
import BenefitList from "./BenefitList";

const Section13 = () => {
  return (
    <SectionWrap
      containerProps={{
        className: "flex justify-center",
      }}
      className="flex justify-center"
      contentContainerProps={{
        className: "flex justify-center items-center w-full flex-col gap-5",
      }}
    >
      <TitleComponent />
      <PricingList />
      <BenefitList />
    </SectionWrap>
  );
};

export default Section13;
