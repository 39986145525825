import { Typography } from "Components/Own";
import HubspotForm from "Components/Own/HubspotForm";
import { localesText } from "Utils/LocalesText.Util";

export default function FormComponent() {
  return (
    <div className="flex flex-col items-center rounded-[40px] border p-10 flex-[3_3_0%]">
      <Typography.Heading1 className="mb-6">
        {localesText("tyro.want_to_start")}
        <span className="text-clever-link font-semibold">
          {localesText("tyro.three_months_access")}
        </span>
      </Typography.Heading1>

      <HubspotForm
        portalId={`${process.env.NEXT_PUBLIC_HUBSPOT_TYRO_PORTAL_ID}`}
        formId={`${process.env.NEXT_PUBLIC_HUBSPOT_TYRO_FORM_ID}`}
      />
    </div>
  );
}
