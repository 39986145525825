import { SectionWrap } from "Components/Layouts";

import TitleComponent from "./Title.Component";
import BenefitsList from "./BenefitList";

const Section2 = () => {
  return (
    <SectionWrap
      containerProps={{
        className: "mt-[120px] md:mt-[180px]",
      }}
      className="flex items-center justify-center"
      contentContainerProps={{
        className:
          "w-full flex flex-col gap-10 md:gap-12 justify-center items-center",
      }}
    >
      <TitleComponent />
      <BenefitsList />
    </SectionWrap>
  );
};

export default Section2;
