import { memo } from "react";

import { LottieImage } from "Components/Own";

import RewardRegulars from "Assets/Lotties/otp_instant_cashback.json";

const ImageComponent = () => (
  <div className="flex-1">
    <div className="relative w-full h-full object-cover">
      <LottieImage options={{ animationData: RewardRegulars }} />
    </div>
  </div>
);

export default memo(ImageComponent);
