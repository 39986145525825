import { memo } from "react";

import { RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

const TitleComponent = () => {
  return (
    <div className="mb-14 max-w-[960px] flex-1 text-center">
      <Typography.SectionTitle className="mb-8">
        {localesText("common.simple")}{" "}
        <RotateHighlightText content={localesText("common.integration")} />
      </Typography.SectionTitle>

      <Typography.Body className="mt-4 text-clever-description">
        {localesText("buy_to_earn.easily_integrate")}
      </Typography.Body>
    </div>
  );
};

export default memo(TitleComponent);
