import { memo } from "react";

import { NormalImage } from "Components/Lib";
import { NextLink } from "Components/Lib/Next";
import { PATHS_NAME } from "Constants/PathName.Constant";
import { classnames } from "Utils/Classnames.Util";
import { generatePath } from "Utils/Common.Util";

const ImageComponent = ({
  title,
  url,
  isReverse = false,
  link,
}: {
  title: string;
  url: string;
  link: string;
  isReverse: boolean;
}) => {
  return (
    <div
      className={classnames("flex flex-1 items-center justify-center", {
        "md:justify-end": isReverse,
        "md:justify-start": !isReverse,
      })}
    >
      <NextLink
        href={generatePath(PATHS_NAME.CASE_STUDY_DETAIL, {
          case_slug: link,
        })}
        className="w-full"
      >
        <div className="overflow-hidden rounded-[40px]">
          <NormalImage src={url} alt={title} height={360} width="100%" />
        </div>
      </NextLink>
    </div>
  );
};

export default memo(ImageComponent);
