import { memo } from "react";

import { Typography } from "Components/Own";

const Solution = ({
  item,
  itemWidth,
}: {
  item: {
    id: number;
    image: any;
    title: string;
    content: string;
  };
  itemWidth: number;
}) => {
  return (
    <div className="flex justify-center rounded-[24px] bg-white-slate p-5">
      <div
        className="flex items-center gap-3"
        style={{ width: itemWidth - 40 }}
        suppressHydrationWarning={true}
      >
        <div className="aspect-square w-[50px]">
          <img src={item.image} width={50} height={50} alt="close-icon" />
        </div>

        <Typography.Body shouldScale={false} className="flex-1 text-dark">
          {item.title}
        </Typography.Body>
      </div>
    </div>
  );
};

export default memo(Solution);
