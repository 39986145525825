import { memo, useEffect, useRef } from "react";

import { LottieImage } from "Components/Own";
import { useIsInViewport } from "Hooks/useIsInViewPort";
import { HOW_WORKS_TYPE } from "Types";
import { localesText } from "Utils/LocalesText.Util";

import HowItWorkThree from "Assets/Lotties/tyro_how_it_work_three.json";

import Title from "../Title.Component";

const PayToTab = ({
  tabName,
  isActive,
  onTabInViewPort,
  paginatorScroll,
}: {
  tabName: HOW_WORKS_TYPE;
  isActive: boolean;
  onTabInViewPort: (name: HOW_WORKS_TYPE) => void;
  paginatorScroll: (id: string) => void;
}) => {
  const ref = useRef<any>(null);

  const isInViewport = useIsInViewport(ref);

  useEffect(() => {
    if (isInViewport && !isActive) {
      onTabInViewPort(tabName);
      paginatorScroll("tab-" + tabName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive, isInViewport]);

  return (
    <>
      <Title description={localesText("tyro.it_work_text_three")} />
      <div ref={ref} className="h-3 w-3" />
      <div className="flex flex-1 sm:px-10 mt-4 md:mt-6 mb-8 md:mb-16">
        <div className="flex-1">
          <LottieImage options={{ animationData: HowItWorkThree }} />
        </div>
      </div>
    </>
  );
};

export default memo(PayToTab);
