import { localesText } from "Utils/LocalesText.Util";

import PricingComponent from "./Pricing.Component";

const data = [
  {
    id: 1,
    title: localesText("tyro.fee_title_one"),
    fee: localesText("tyro.fee_one"),
    percentFee: localesText("tyro.fee"),
    feeContent: localesText("tyro.fee_content_one"),
    percentFeeContent: localesText("tyro.percent_fee_content_one"),
  },
  {
    id: 2,
    title: localesText("tyro.fee_title_two"),
    fee: localesText("tyro.fee_two"),
    percentFee: localesText("tyro.fee"),
    feeContent: localesText("tyro.fee_content_two"),
    percentFeeContent: localesText("tyro.percent_fee_content_two"),
  },
];

const PricingList = () => {
  return (
    <div className="flex flex-col md:flex-row w-full gap-5">
      {data.map((item) => (
        <PricingComponent key={item.id} item={item} />
      ))}
    </div>
  );
};

export default PricingList;
