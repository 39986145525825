import { memo } from "react";

import { classnames } from "Utils/Classnames.Util";
import { ExpandButton, Typography } from "Components/Own";

const Content = ({
  id,
  title,
  content,
  isExpanding,
  onToggle,
}: {
  id: number;
  title: string;
  content: string;
  isExpanding: boolean;
  onToggle: (id: number) => void;
}) => {
  return (
    <div className="absolute top-0 left-0 bottom-0 z-30 flex flex-col p-10">
      <Typography.Heading2
        shouldScale={false}
        className={classnames("font-normal transition-all duration-500", {
          "mb-2 w-[70%] flex-1": !isExpanding,
          "mb-5 w-[50%]": isExpanding,
        })}
      >
        {title}
      </Typography.Heading2>

      <Typography.Content
        shouldScale={false}
        className={classnames(
          "flex-1 overflow-hidden font-normal text-clever-description transition-all duration-500",
          {
            "w-[70%] opacity-0": !isExpanding,
            "mb-2 w-[45%] opacity-100": isExpanding,
          }
        )}
      >
        {content}
      </Typography.Content>

      <ExpandButton
        inputProps={{
          checked: isExpanding,
          onChange: (event) => {
            event.preventDefault();
            event.stopPropagation();

            onToggle(id);
          },
        }}
      />
    </div>
  );
};

export default memo(Content);
