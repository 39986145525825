import { memo } from "react";

import { Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

const Pricing = ({
  item,
}: {
  item: {
    id: number;
    title: string;
    fee: string;
    feeContent: string;
    percentFee: string;
    percentFeeContent: string;
  };
}) => {
  return (
    <div className="flex flex-col flex-1 rounded-[24px] bg-white-slate p-6 md:p-10">
      <Typography.Heading2 className="mb-8">{item.title}</Typography.Heading2>
      <div className="flex flex-col gap-5">
        <div>
          <Typography.Heading2 className="font-semibold">
            {item.fee}
          </Typography.Heading2>
          <Typography.Content>{item.feeContent}</Typography.Content>
        </div>
        <div>
          <Typography.Heading2 className="font-semibold">
            {item.percentFee}
          </Typography.Heading2>
          <Typography.Content>{item.percentFeeContent}</Typography.Content>
        </div>
      </div>
    </div>
  );
};

export default memo(Pricing);
