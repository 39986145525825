import { SectionWrap } from "Components/Layouts";
import { MAX_INNER_CONTENT_WIDTH } from "Constants/Common.Constant";

import TitleComponent from "./Title.Component";
import ImageComponent from "./Image.Component";

const Section4 = () => {
  return (
    <SectionWrap
      containerProps={{
        className: "my-8",
      }}
      contentContainerProps={{
        className: "flex flex-col gap-6 md:flex-row",
      }}
      className="flex items-center justify-center"
    >
      <TitleComponent />
      <ImageComponent />
    </SectionWrap>
  );
};

export default Section4;
