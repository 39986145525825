import { Typography } from "Components/Own";

const Instant = ({
  item,
  itemWidth,
}: {
  item: {
    id: number;
    image: any;
    title: string;
    content: string;
  };
  itemWidth: number;
}) => {
  return (
    <div
      className="flex flex-col overflow-hidden"
      style={{ width: itemWidth }}
      suppressHydrationWarning={true}
    >
      <div className="mb-5 flex aspect-square w-[60px]">
        <img src={item.image} width={60} height={60} alt="close-icon" />
      </div>
      <Typography.Heading2 className="w-[90%] text-xl font-normal mb-4 sm:text-2xl">
        {item.title}
      </Typography.Heading2>
      <Typography.Description className="text-clever-description">
        {item.content}
      </Typography.Description>
    </div>
  );
};

export default Instant;
