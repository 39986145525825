import { useCallback, useState } from "react";

export const useExpand = (defaultExpandingId?: number) => {
  const [expandingId, setExpandingId] = useState<any>(defaultExpandingId);

  const onToggle = useCallback(
    (expandId: number) => {
      if (expandingId === expandId) {
        setExpandingId(undefined);
      } else {
        setExpandingId(expandId);
      }
    },
    [expandingId]
  );

  return { expandingId, onToggle };
};
