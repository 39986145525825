import { MAX_INNER_CONTENT_WIDTH } from "Constants/Common.Constant";
import { classnames } from "Utils/Classnames.Util";

const SectionWrap = ({
  containerProps = {},
  contentContainerProps = {},
  isLimitMaxInnerContentWidth = true,
  isLimitMaxContentWidth = true,
  noSidePadding = false,
  className,
  style,
  children,
  ...rest
}: React.HTMLProps<HTMLDivElement> & {
  containerProps?: React.HTMLProps<HTMLDivElement>;
  isLimitMaxInnerContentWidth?: boolean;
  isLimitMaxContentWidth?: boolean;
  noSidePadding?: boolean;
  contentContainerProps?: React.HTMLProps<HTMLDivElement>;
}) => {
  const {
    className: containerClassName,
    style: containerStyle,
    ...containerRest
  } = containerProps;

  const {
    className: contentContainerClassName,
    style: contentContainerStyle,
    ...contentContainerRest
  } = contentContainerProps;

  return (
    <section
      className={classnames(
        "flex justify-center",
        {
          "px-[4%] sm:px-[3%] md:px-[2%]": !noSidePadding,
        },
        containerClassName,
      )}
      {...containerRest}
    >
      <div
        {...rest}
        className={classnames("w-full", className)}
        style={{
          ...(isLimitMaxContentWidth
            ? { maxWidth: MAX_INNER_CONTENT_WIDTH }
            : {}),
          ...style,
        }}
      >
        <div
          className={contentContainerClassName}
          style={{
            ...contentContainerStyle,
          }}
          {...contentContainerRest}
        >
          {children}
        </div>
      </div>
    </section>
  );
};

export default SectionWrap;
