import { RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

export default function TitleComponent() {
  return (
    <div>
      <Typography.Body className="font-semibold text-center">
        {localesText("tyro.easy_set_up")}
      </Typography.Body>
      <Typography.SectionTitle className="text-center">
        {localesText("tyro.get_started")}
        <RotateHighlightText
          content={localesText("tyro.now")}
          className="ml-2"
        />
      </Typography.SectionTitle>
    </div>
  );
}
