import { WrapList } from "Components/Own";
import { useMobileScreen } from "Hooks/useMediaQuery";
import { localesText } from "Utils/LocalesText.Util";

import InstantCashback from "./InstantCashback.Component";

export default function InstantCashbackList() {
  const isMobile = useMobileScreen();

  const data = [
    {
      id: 1,
      image: "Assets/Svg/like.svg",
      title: localesText("tyro.better_faster"),
      content: localesText("tyro.customer_earn_cashback"),
    },
    {
      id: 2,
      image: "Assets/Svg/pos.svg",
      title: localesText("tyro.seamless"),
      content: localesText("tyro.no_clunky"),
    },
    {
      id: 3,
      image: "Assets/Svg/star_green.svg",
      title: localesText("tyro.boost_customer"),
      content: localesText("tyro.offering_instant"),
    },
    {
      id: 4,
      image: "Assets/Svg/pig.svg",
      title: localesText("tyro.low_cost"),
      content: localesText("tyro.first_three_month"),
    },
    {
      id: 5,
      image: "Assets/Svg/money.svg",
      title: localesText("tyro.generate_revenue"),
      content: localesText("tyro.drive_repeat"),
    },
    {
      id: 6,
      image: "Assets/Svg/photo.svg",
      title: localesText("tyro.enhanced_campaign"),
      content: localesText("tyro.promotional_materials"),
    },
  ];

  return (
    <WrapList
      data={data}
      ItemComponent={InstantCashback}
      itemSize={isMobile ? 340 : 310}
      shoulScale={false}
      containerClassName="flex-1"
      contentContainerClassName="gap-y-10"
      fillNumber={0}
    />
  );
}
