import { memo } from "react";

import { RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

const TitleComponent = () => {
  return (
    <div className="flex flex-1 flex-col justify-center">
      <div className="mb-6 text-left">
        <Typography.SectionTitle>
          {localesText("in_store.enjoy")}

          <RotateHighlightText
            content={localesText("common.reward")}
            className="ml-2 inline-block"
          />
          {localesText("in_store.your_way")}
        </Typography.SectionTitle>
      </div>

      <div className="text-clever-description sm:pr-4 md:pr-11">
        <Typography.Body className="mb-5">
          {localesText("in_store.stay_on_top")}
        </Typography.Body>
        <Typography.Body className="mb-5">
          {localesText("in_store.track_your_earnings")}
        </Typography.Body>
        <Typography.Body>
          {localesText("in_store.cash_out_your_rewards")}
        </Typography.Body>
      </div>
    </div>
  );
};

export default memo(TitleComponent);
