import { SectionWrap } from "Components/Layouts";

import ImageComponent from "./Image.Component";
import TitleComponent from "./Title.Component";

const Section7 = () => {
  return (
    <SectionWrap
      contentContainerProps={{
        className: "flex flex-col gap-10 md:gap-6 md:flex-row",
      }}
      className="flex items-center justify-center"
    >
      <ImageComponent />
      <TitleComponent />
    </SectionWrap>
  );
};

export default Section7;
