import { memo } from "react";

import { NormalImage } from "Components/Lib";

const ImageComponent = ({ url, title }: { url: string; title: string }) => {
  return (
    <div className="flex flex-1 items-center justify-center">
      <div className="max-h-[360px] max-w-[590px] overflow-hidden rounded-[40px]">
        <NormalImage src={url} height={360} width="100%" alt={title} />
      </div>
    </div>
  );
};

export default memo(ImageComponent);
