import { memo } from "react";

import { LottieImage } from "Components/Own";

import InStoreBanner from "Assets/Lotties/in_store_banner.json";

function ImageComponent() {
  return (
    <div className="relative flex">
      <div className="max-w-[700px]">
        <LottieImage options={{ animationData: InStoreBanner }} />
      </div>
    </div>
  );
}

export default memo(ImageComponent);
