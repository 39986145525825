import { memo } from "react";

import { Typography } from "Components/Own";

import { formatMoment } from "Utils/Moment.Util";

const TitleComponent = ({
  content,
  date,
}: {
  content: string;
  date: string;
}) => {
  return (
    <div className="flex flex-1 flex-col justify-center">
      <Typography.Description className="mb-4 text-white opacity-70">
        {formatMoment({ date, format: "DD MMMM YYYY" })}
      </Typography.Description>

      <Typography.Heading1 className="text-white">
        {content}
      </Typography.Heading1>
    </div>
  );
};

export default memo(TitleComponent);
