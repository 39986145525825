import { memo } from "react";

import { RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";
import { _toLower } from "Utils/Lodash.Util";

const TitleComponent = () => (
  <div className="flex flex-col items-center text-center">
    <Typography.SectionTitle className="mb-2 max-w-[620px]">
      {localesText("payto.take_payto")}{" "}
      <RotateHighlightText content={localesText("common.hello_clever")} />
    </Typography.SectionTitle>
  </div>
);

export default memo(TitleComponent);
