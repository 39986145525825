import { memo } from "react";

import { NormalImage } from "Components/Lib";
import { NextLink } from "Components/Lib/Next";
import { PATHS_NAME } from "Constants/PathName.Constant";
import { generatePath } from "Utils/Common.Util";

const ImageComponent = ({
  title,
  url,
  link,
}: {
  title: string;
  url: string;
  link: string;
}) => {
  return (
    <div className="flex flex-1 items-center justify-center">
      <NextLink
        href={generatePath(PATHS_NAME.BLOG_DETAIL, {
          blog_slug: link,
        })}
      >
        <div className="overflow-hidden rounded-[40px]">
          <NormalImage src={url} alt={title} height={360} width="100%" />
        </div>
      </NextLink>
    </div>
  );
};

export default memo(ImageComponent);
