import { RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

export default function TitleComponent() {
  return (
    <div className="flex flex-col justify-center items-center gap-6 mb-12">
      <Typography.SectionTitle className="text-center">
        <RotateHighlightText
          content={localesText("tyro.simple")}
          className="mr-2"
        />
        {localesText("tyro.pricing")}
      </Typography.SectionTitle>
    </div>
  );
}
