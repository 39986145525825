import { SectionWrap } from "Components/Layouts";
import { MAX_INNER_CONTENT_WIDTH } from "Constants/Common.Constant";

import TitleComponent from "./Title.Component";
import ImageComponent from "./Image.Component";

const Section4 = () => {
  return (
    <div className="my-8 mt-[240px] flex justify-center">
      <SectionWrap className="flex flex-col items-center">
        <div
          className="flex w-full flex-col gap-10 md:flex-row"
          style={{ maxWidth: MAX_INNER_CONTENT_WIDTH }}
        >
          <TitleComponent />
          <ImageComponent />
        </div>
      </SectionWrap>
    </div>
  );
};

export default Section4;
