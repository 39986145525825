import { memo } from "react";

import { LottieImage } from "Components/Own";
import { LOTTIE_URLS } from "Constants/URL.Constant";

function ImageComponent() {
  return (
    <div className="relative flex">
      <LottieImage
        options={{
          animationData: "",
          url: LOTTIE_URLS.XERO_BANNER,
          autoplay: true,
        }}
        useObserver={false}
      />
    </div>
  );
}

export default memo(ImageComponent);
