import { memo } from "react";

import { Button, LottieImage } from "Components/Own";
import { NextLink } from "Components/Lib/Next";
import { localesText } from "Utils/LocalesText.Util";
import { PATHS_NAME } from "Constants/PathName.Constant";
import { LOTTIE_URLS } from "Constants/URL.Constant";

const ImageComponent = () => {
  return (
    <div className="relative flex max-w-[950px] flex-col items-center justify-center">
      <NextLink
        href={PATHS_NAME.MERCHANT_API}
        className="mb-5 md:absolute md:-top-2 md:z-10 md:mb-0"
      >
        <Button
          buttonSize="large"
          className="rounded-full"
          wide
          buttonType="primary"
        >
          {localesText("common.learn_more")}
        </Button>
      </NextLink>

      <LottieImage
        options={{ animationData: "", url: LOTTIE_URLS.DEVELOP_TEAM }}
      />
    </div>
  );
};

export default memo(ImageComponent);
