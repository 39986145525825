import { memo } from "react";

import { NormalImage } from "Components/Lib";

import AboutBanner from "Assets/Images/Others/about_banner.png";

const MerchantDiscover = () => {
  return (
    <div className="relative flex flex-1 flex-col items-end">
      <NormalImage
        src={AboutBanner.src}
        alt="flower-mobile"
        width={700}
        height={758}
        className="inline-block"
        sizes="40vw"
      />
    </div>
  );
};

export default memo(MerchantDiscover);
