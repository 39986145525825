import { memo } from "react";

import { SectionWrap } from "Components/Layouts";

import TitleComponent from "./Title.Component";
import SolutionList from "./SolutionList";

const Section11 = () => {
  return (
    <SectionWrap
      className="flex items-center justify-center"
      contentContainerProps={{
        className: "flex w-full flex-col items-center gap-10",
      }}
    >
      <TitleComponent />
      <SolutionList />
    </SectionWrap>
  );
};

export default memo(Section11);
