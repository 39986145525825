import { memo, useEffect, useState } from "react";

import { localesText } from "Utils/LocalesText.Util";
import { RotateHighlightText, Typography, HubspotForm } from "Components/Own";
import { formatNumber } from "Utils/Number.Util";
import { getStoresCount } from "Services";
import { NormalImage } from "Components/Lib";
import { _upperFirst } from "Utils/Lodash.Util";

import unionIcon from "Assets/Images/Icons/union.png";

const TitleComponent = () => {
  const [storeCount, setStoreCount] = useState<number>(0);

  useEffect(() => {
    const fetchStoreCount = async () => {
      const response = await getStoresCount();
      setStoreCount(response.storeCount);
    };
    fetchStoreCount();
  }, []);

  return (
    <div className="relative flex flex-1 flex-col items-center text-center">
      <Typography.Giant className="mb-6 text-white sm:w-[90%]">
        {localesText("buy_to_earn.real_time_payment")}{" "}
        <RotateHighlightText content={localesText("buy_to_earn.rewards")} />{" "}
        {_upperFirst(localesText("buy_to_earn.customers"))}
      </Typography.Giant>

      <Typography.Content className="text-center text-white md:w-[70%] lg:w-[60%]">
        {localesText("buy_to_earn.boost_your_sales", {
          amount: formatNumber(storeCount),
        })}
      </Typography.Content>

      <div className="mb-[-190px] mt-6 flex w-full flex-1 sm:w-[80%] md:px-[80px] md:py-[40px] lg:w-[85%] xl:w-full">
        <NormalImage
          className="mb-[150px] mr-[16px] hidden h-[150px] self-end xl:block"
          src={unionIcon.src}
          alt="union-1"
          width={150}
          height={150}
        />

        <HubspotForm />

        <NormalImage
          className="ml-[16px] hidden h-[150px] self-end xl:block"
          src={unionIcon.src}
          alt="union-2"
          width={150}
          height={150}
        />
      </div>
    </div>
  );
};

export default memo(TitleComponent);
