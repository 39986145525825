import { memo } from "react";

import { classnames } from "Utils/Classnames.Util";
import { SectionWrap } from "Components/Layouts";
import { MAX_INNER_CONTENT_WIDTH } from "Constants/Common.Constant";

import TitleComponent from "./Title.Component";

const Section1 = () => {
  return (
    <div className="flex justify-center bg-clever-primary">
      <SectionWrap className="flex justify-center pt-24">
        <div
          className={classnames(
            "flex w-full flex-col items-center lg:flex-row lg:items-start"
          )}
          style={{ maxWidth: MAX_INNER_CONTENT_WIDTH }}
        >
          <TitleComponent />
        </div>
      </SectionWrap>
    </div>
  );
};

export default memo(Section1);
