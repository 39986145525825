export const API = {
  REFERRAL_BONUS: "/clever_shops/referral_bonus",
  INSTANT_DEALS: "/clever_shops",
  INSTANT_DEAL_DETAIL: "/clever_shops/:slug/detail",
  STORES_COUNT: "/clever_shops/count",
  BANKS: "/clever_shops/list_banks",
  API_STATUS: "https://api-status.helloclever.co/health-check",

  // Other Links
  // MEDIUM_BLOGS:
  //   "https://woo.cleverpay.store/wp-json/wp/v2/posts?_embed&per_page=200",
  MEDIUM_BLOGS: "https://woo.cleverpay.store/wp-json/wp/v2/posts?_embed",

};
