import { classnames } from "Utils/Classnames.Util";
import { getBlogImageSize } from "Utils/Common.Util";

import TitleComponent from "./Title.Component";
import ImageComponent from "./Image.Component";

const PostItem = ({
  data = {},
  isReverse = false,
}: {
  data: any;
  isReverse: boolean;
}) => {
  const imageUrls = getBlogImageSize(data);

  return (
    <div
      className={classnames("flex w-full flex-col gap-5 lg:gap-12", {
        "md:flex-row-reverse md:gap-0": isReverse,
        "md:flex-row md:gap-0": !isReverse,
      })}
    >
      <ImageComponent
        title={data?.title?.rendered}
        url={imageUrls.medium || imageUrls.original}
        isReverse={isReverse}
        link={data.slug}
      />
      <TitleComponent
        date={data.date}
        content={data?.title?.rendered}
        isReverse={isReverse}
        link={data.slug}
      />
    </div>
  );
};

export default PostItem;
