import { useMemo } from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";

import { logAnalyticEvent } from "Utils/Analytics.Util";
import { TRACKING_EVENTS } from "Constants/Event.Constant";
import { NextImage } from "Components/Lib/Next";
import { Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

import facebook from "Assets/Images/Socials/facebook_black.png";
import twitter from "Assets/Images/Socials/twitter_black.png";
import linkedIn from "Assets/Images/Socials/linkedIn_black.png";

const BlogShare = ({ title }: { title: string }) => {
  const pathName = useMemo(() => {
    if (typeof window !== "undefined") {
      return window.location.href;
    }
    return "";
  }, []);

  const onClickShare = (channel = "") => {
    logAnalyticEvent({
      key: TRACKING_EVENTS.socialChannelSharedClicked,
      params: { channel },
    });
  };

  return (
    <div className="flex flex-row items-center justify-center gap-6 md:flex-col">
      <FacebookShareButton
        onClick={() => onClickShare("facebook")}
        hashtag="#hello_clever"
        url={pathName}
      >
        <div className="relative flex h-[48px] w-[48px] items-center justify-center overflow-hidden rounded-full bg-white-slate">
          <NextImage src={facebook} alt="facebook" width={20} height={20} />
        </div>
      </FacebookShareButton>

      <TwitterShareButton
        onClick={() => onClickShare("twitter")}
        hashtags={["hello_clever"]}
        url={pathName}
        via={"hello_clever"}
        related={["hello_clever"]}
      >
        <div className="relative flex h-[48px] w-[48px] items-center justify-center overflow-hidden rounded-full bg-white-slate">
          <NextImage src={twitter} alt="twitter" width={20} height={20} />
        </div>
      </TwitterShareButton>

      <LinkedinShareButton
        onClick={() => onClickShare("linkedIn")}
        url={pathName}
        title={title}
        source="https://helloclever.co/"
      >
        <div className="relative flex h-[48px] w-[48px] items-center justify-center overflow-hidden rounded-full bg-white-slate">
          <NextImage src={linkedIn} alt="linkedin" width={20} height={20} />
        </div>
      </LinkedinShareButton>

      <div className="mt-3 hidden gap-2 md:flex md:-rotate-90 md:items-center">
        <Typography.Small className="font-normal">
          {localesText("common.share")}
        </Typography.Small>
        <strong className="pb-3 font-bold">___</strong>
      </div>
    </div>
  );
};

export default BlogShare;
