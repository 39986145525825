import { memo, useEffect, useState } from "react";

import { Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";
import { getStoresCount } from "Services";
import { formatNumber } from "Utils/Number.Util";

const TitleComponent = () => {
  const [storeCount, setStoreCount] = useState<number>(0);

  useEffect(() => {
    const fetchStoreCount = async () => {
      const response = await getStoresCount();
      setStoreCount(response.storeCount);
    };
    fetchStoreCount();
  }, []);

  return (
    <div className="mb-8 flex flex-col items-center">
      <Typography.Giant className="max-w-[950px] text-center text-white">
        {localesText("home.shop_with_brands")} {localesText("home.you_love")}{" "}
        {localesText("common.instant_cashback")}
      </Typography.Giant>

      <Typography.Content className="mt-2 text-center text-white sm:mt-4 sm:w-[70%]">
        {localesText("home.the_first_australian", {
          amount: formatNumber(storeCount),
        })}
      </Typography.Content>
    </div>
  );
};

export default memo(TitleComponent);
