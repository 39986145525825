import { SectionWrap } from "Components/Layouts";

import TitleComponent from "./Title.Component";
import CashFlow from "./CashFlow";

const Section5 = () => {
  return (
    <SectionWrap
      containerProps={{ className: "sm:py-12" }}
      contentContainerProps={{
        className: "flex w-full flex-col",
      }}
      className="flex items-center justify-center"
    >
      <TitleComponent />
      <CashFlow />
    </SectionWrap>
  );
};

export default Section5;
