import { RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";
import { classnames } from "Utils/Classnames.Util";

const TitleComponent = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => (
  <div
    className={classnames(
      "flex flex-1",
      "px-6 sm:px-10 md:pl-16",
      "my-8 sm:my-16 md:my-20"
    )}
  >
    <div className="flex flex-1 flex-col justify-center gap-8 md:gap-14 lg:gap-20">
      <Typography.SectionTitle>
        <RotateHighlightText content={localesText("xero.how")} />
        {localesText("xero.it_works")}
      </Typography.SectionTitle>
      <div className="flex flex-1 flex-col gap-3 md:w-[85%]">
        <Typography.Body className="font-bold uppercase">
          {title}
        </Typography.Body>
        <Typography.Body>{description}</Typography.Body>
      </div>
    </div>
  </div>
);

export default TitleComponent;
