import { memo } from "react";

import { SectionWrap } from "Components/Layouts";

import TitleComponent from "./Title.Component";
import ImageComponent from "./Image.Component";

const Launtel = ({ slug }: { slug: string }) => {
  return (
    <SectionWrap
      containerProps={{ className: "flex justify-center -mt-8" }}
      className="flex justify-center"
      contentContainerProps={{
        className:
          "flex justify-center items-center flex-col md:flex-row overflow-hidden rounded-[40px] bg-white-slate pt-16 w-full md:gap-10 gap-4 px-16",
      }}
    >
      <TitleComponent slug={slug} />
      <ImageComponent />
    </SectionWrap>
  );
};

export default memo(Launtel);
