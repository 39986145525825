import { SectionWrap } from "Components/Layouts";
import { InstantDealProps } from "Types";

import TitleComponent from "./Title.Component";
import OfferList from "./OfferList";

const Section2 = ({ instantDeals }: { instantDeals: InstantDealProps[] }) => {
  return (
    <>
      <SectionWrap
        contentContainerProps={{
          className: "mt-24 flex w-full flex-col",
        }}
        className="mt-[200px] flex items-center justify-center md:mt-[300px]"
      >
        <TitleComponent />
      </SectionWrap>
      <SectionWrap
        contentContainerProps={{
          className: "flex w-full flex-col",
        }}
        className="flex items-center justify-center"
        noSidePadding
        isLimitMaxContentWidth={false}
      >
        <OfferList instantDeals={instantDeals} />
      </SectionWrap>
    </>
  );
};

export default Section2;
