import { ExpandButton, Typography } from "Components/Own";

const CollapseComponent = ({
  index,
  id,
  title,
  content,
  highlight_content,
  isExpanding,
  onToggle,
}: {
  index: number;
  id: number;
  title: string;
  highlight_content: string;
  content: string;
  isExpanding: boolean;
  onToggle: (id: number) => void;
}) => {
  return (
    <div
      tabIndex={index}
      className={`collapse border-b-[1px] border-b-base-300 ${
        !isExpanding && "collapse-close"
      }`}
    >
      <input
        type="checkbox"
        className="cursor-pointer"
        checked={isExpanding}
        onChange={(event) => onToggle(id)}
      />

      <div className="collapse-title px-0 py-6">
        <div className="flex items-center justify-between gap-4 overflow-hidden">
          <Typography.Heading2
            className="flex-1 text-left"
            onClick={(event) => onToggle(id)}
          >
            {title}
          </Typography.Heading2>
          <ExpandButton
            inputProps={{
              checked: isExpanding,
              onChange: (event) => {
                onToggle(id);
              },
            }}
          />
        </div>
      </div>
      <div tabIndex={index} className="collapse-content pl-0">
        <Typography.Body className="py-4 text-clever-description">
          {content}
          {highlight_content ? (
            <strong className="text-clever-link"> {highlight_content}</strong>
          ) : (
            ""
          )}
        </Typography.Body>
      </div>
    </div>
  );
};

export default CollapseComponent;
