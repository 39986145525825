import { RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

export default function TitleComponent() {
  return (
    <div>
      <Typography.SectionTitle className="text-center">
        <RotateHighlightText
          content={localesText("common.real_time")}
          className="inline-block"
        />
        {localesText("in_store.rewards_in_store")}
      </Typography.SectionTitle>
    </div>
  );
}
