import { memo } from "react";

import { LottieImage } from "Components/Own";
import { LOTTIE_URLS } from "Constants/URL.Constant";

const ImageComponent = () => (
  <div className="flex flex-1 items-center justify-center">
    <LottieImage
      options={{ animationData: "", url: LOTTIE_URLS.WORK_ECOMMERCE }}
    />
  </div>
);

export default memo(ImageComponent);
