import { RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";
import { _toLower } from "Utils/Lodash.Util";
import { MAX_INNER_CONTENT_WIDTH } from "Constants/Common.Constant";

import WorkWithUs from "./WorkWithUs";

const TitleComponent = () => (
  <div
    className="flex w-full items-center justify-between self-center"
    style={{ maxWidth: MAX_INNER_CONTENT_WIDTH }}
  >
    <div className="mb-4 flex-1 text-center md:text-left">
      <Typography.SectionTitle>
        {localesText("common.related")}{" "}
        <RotateHighlightText content={localesText("common.articles")} />
      </Typography.SectionTitle>
    </div>

    <div className="hidden justify-end md:flex">
      <WorkWithUs buttonSize="large" />
    </div>
  </div>
);

export default TitleComponent;
