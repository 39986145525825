import { SectionWrap } from "Components/Layouts";

import BlogShare from "./BlogShare.Component";
import BlogContent from "./BlogContent.Component";

const Section2 = ({ title, content }: { title: string; content: string }) => {
  return (
    <SectionWrap
      containerProps={{
        className: "pt-24 pb-8",
      }}
      className="flex justify-center"
      contentContainerProps={{
        className:
          "flex flex-col items-center md:items-start gap-10 md:flex-row w-full",
      }}
    >
      <BlogShare title={title} />
      <BlogContent content={content} />
    </SectionWrap>
  );
};

export default Section2;
