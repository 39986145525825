import { memo } from "react";

import { RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

const TitleComponent = () => {
  return (
    <div className="flex flex-1 flex-col justify-center">
      <div className="mb-6 text-left">
        <Typography.SectionTitle>
          <RotateHighlightText
            content={localesText("tyro.reward")}
            className="mr-3 inline-block"
          />
          {localesText("tyro.customers_in_real_time")}
        </Typography.SectionTitle>
      </div>

      <Typography.Body className="mb-6 text-clever-description sm:pr-4 md:pr-11">
        {localesText("tyro.cashback_reward")}
      </Typography.Body>
    </div>
  );
};

export default memo(TitleComponent);
