import { useEffect } from "react";

import { logAnalyticEvent } from "Utils/Analytics.Util";
import { TRACKING_EVENTS } from "Constants/Event.Constant";
import { checkOS } from "Utils/Common.Util";
import { VentureSection, PaymentsSliderSection } from "Components/Own";

import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section5 from "./Section5";
import Section6 from "./Section6";
import Section7 from "./Section7";
import Section8 from "./Section8";

const BuyToEarn = () => {
  useEffect(() => {
    logAnalyticEvent({
      key: TRACKING_EVENTS.merchantPageLanded,
      params: { OS: checkOS() },
    });
  }, []);

  return (
    <div>
      <Section1 />
      <Section2 />
      <Section3 />
      <PaymentsSliderSection />
      <Section5 />
      <Section6 />
      <Section7 />
      <Section8 />
      <VentureSection />
    </div>
  );
};

export default BuyToEarn;
