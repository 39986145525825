import { memo } from "react";

import { NormalImage } from "Components/Lib";

const Image = ({
  image,
  itemWidth,
}: {
  image: any;
  isExpanding: boolean;
  itemWidth: number;
}) => {
  return (
    <div
      className="relative aspect-square h-full overflow-hidden rounded-[40px] transition-all duration-500"
      style={{ maxWidth: itemWidth }}
    >
      <NormalImage
        src={image}
        alt="cash-flow"
        className="mx-auto h-full w-full object-cover object-center"
        sizes="325px"
      />
    </div>
  );
};

export default memo(Image);
