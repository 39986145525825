import { memo } from "react";

import { localesText } from "Utils/LocalesText.Util";
import { RotateHighlightText, Typography, HubspotForm } from "Components/Own";
import unionIcon from "Assets/Images/Icons/union.png";
import { NormalImage } from "Components/Lib";

const TitleComponent = () => {
  return (
    <div className="relative mb-8 flex flex-1 flex-col items-center text-center">
      <Typography.Giant className="mb-6 text-white sm:w-[90%]">
        {localesText("merchant_api.we_work_across")}{" "}
        <RotateHighlightText content={localesText("common.australian")} />{" "}
        {localesText("merchant_api.industries_from")}
      </Typography.Giant>

      <Typography.Content className="text-center text-white md:w-[70%] lg:w-[60%]">
        {localesText("merchant_api.boost_your_sales")}
      </Typography.Content>

      <div className="mb-[-286px] mt-6 flex w-full flex-1 sm:w-[80%] md:px-[80px] md:py-[40px] lg:w-[85%] xl:w-full">
        <NormalImage
          className="mb-[150px] mr-[16px] hidden h-[150px] self-end xl:block"
          src={unionIcon.src}
          alt="union-1"
          width={150}
          height={150}
        />

        <HubspotForm />

        <NormalImage
          className="ml-[16px] hidden h-[150px] self-end xl:block"
          src={unionIcon.src}
          alt="union-2"
          width={150}
          height={150}
        />
      </div>
    </div>
  );
};

export default memo(TitleComponent);
