import { Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

import Checked from "Assets/Images/Icons/checked.png";

const leftData = [
  {
    id: 1,
    description: localesText("tyro.offer_cashback"),
  },
  {
    id: 2,
    description: localesText("tyro.gain_access"),
  },
  {
    id: 3,
    description: localesText("tyro.onboarding_session"),
  },

  {
    id: 4,
    description: localesText("tyro.no_manual"),
  },
];

const rightData = [
  {
    id: 5,
    description: localesText("tyro.seamless_integration"),
  },
  {
    id: 6,
    description: localesText("tyro.encourage_customer"),
  },
  {
    id: 7,
    description: localesText("tyro.track_valuable"),
  },
  {
    id: 8,
    description: localesText("tyro.enjoy_fee"),
  },
];

const BenefitList = () => {
  return (
    <div className="w-full p-6 md:p-10 bg-white-slate rounded-[24px]">
      <Typography.Heading1 className="mb-10">
        {localesText("tyro.included")}:
      </Typography.Heading1>
      <div className="flex flex-col md:flex-row gap-4 md:gap-8">
        <div className="left flex-1 flex flex-col gap-4 md:gap-8">
          {leftData.map((item) => (
            <div key={item.id} className="flex items-center gap-4">
              <div className="aspect-square w-[32px] h-[32px]">
                <img
                  src={Checked.src}
                  width={32}
                  height={32}
                  alt="close-icon"
                />
              </div>
              <Typography.Description>
                {item.description}
              </Typography.Description>
            </div>
          ))}
        </div>
        <div className="left flex-1 flex flex-col gap-4 md:gap-8">
          {rightData.map((item) => (
            <div key={item.id} className="flex items-center gap-4">
              <div className="aspect-square w-[32px] h-[32px]">
                <img
                  src={Checked.src}
                  width={32}
                  height={32}
                  alt="close-icon"
                />
              </div>
              <Typography.Description>
                {item.description}
              </Typography.Description>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BenefitList;
