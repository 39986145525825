import { RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

const TitleComponent = () => {
  return (
    <div className="flex flex-col">
      <div className="mb-6">
        <Typography.SectionTitle>
          {localesText("payto.instant")},
          <RotateHighlightText content={localesText("common.clever")} />
        </Typography.SectionTitle>
        <Typography.SectionTitle>
          {localesText("payto.payments_are_here")}
        </Typography.SectionTitle>
      </div>

      <div className="flex flex-col gap-6 lg:flex-row lg:gap-14">
        <div className="flex-1">
          <Typography.Content className="text-clever-description">
            {localesText("payto.simplify_your_payment")}
          </Typography.Content>
        </div>
        <div className="flex-1">
          <Typography.Content className="text-clever-description">
            {localesText("payto.enhance_your_cash_flow")}
          </Typography.Content>
        </div>
      </div>
    </div>
  );
};

export default TitleComponent;
