import { memo } from "react";

import { NormalImage } from "Components/Lib";
import { LottieImage } from "Components/Own";
import { classnames } from "Utils/Classnames.Util";
import { LOTTIE_URLS } from "Constants/URL.Constant";

import PaytoBanner from "Assets/Images/Others/payto_page_banner.png";

const Banner = () => {
  return (
    <div className="relative flex py-7">
      <div
        className={classnames(
          "absolute flex max-h-[100px] max-w-[350px]",
          "lg:-left-[30%] lg:top-[25%]",
          "-top-[5%] left-0"
        )}
      >
        <LottieImage
          height={"auto"}
          width={"auto"}
          options={{
            animationData: "",
            url: LOTTIE_URLS.PAY_WITH_PAYTO,
            autoplay: true,
          }}
          useObserver={false}
        />
      </div>
      <div
        className={classnames(
          "absolute bottom-[25%] flex max-h-[100px] max-w-[350px]",
          "right-0 lg:-right-[30%]"
        )}
      >
        <LottieImage
          height={"auto"}
          width={"auto"}
          options={{
            animationData: "",
            url: LOTTIE_URLS.AUTHORISATION_REQUEST,
            autoplay: true,
          }}
          useObserver={false}
        />
      </div>
      <NormalImage
        src={PaytoBanner.src}
        alt="payto"
        width={540}
        height={540}
        sizes="80vw"
      />
    </div>
  );
};

export default memo(Banner);
