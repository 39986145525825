import { memo } from "react";

import { NextImage } from "Components/Lib/Next";
import { Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";
import { classnames } from "Utils/Classnames.Util";

const PlatFormItem = ({
  item,
  itemWidth,
}: {
  item: {
    id: number;
    image: any;
    alt: string;
    width: number;
    isCommingSoon: boolean;
  };
  itemWidth: number;
}) => {
  return (
    <div
      className="relative flex items-center justify-center overflow-hidden rounded-2xl bg-white-slate sm:rounded-3xl"
      style={{ width: itemWidth, height: 0.51 * itemWidth }}
      suppressHydrationWarning={true}
    >
      <NextImage
        src={item.image}
        alt={item.alt}
        width={item.width}
        height={32}
        className={classnames({ "opacity-50": item.isCommingSoon })}
      />

      {item.isCommingSoon && (
        <div className="absolute top-5 right-5 rounded-3xl bg-black py-1 px-2 font-semibold text-white">
          <Typography.Small>
            {localesText("common.coming_soon")}
          </Typography.Small>
        </div>
      )}
    </div>
  );
};

export default memo(PlatFormItem);
