import { WrapList } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";
import { useMobileScreen } from "Hooks/useMediaQuery";

import Solution from "./Solution.Component";

const data = [
  {
    id: 1,
    image: "Assets/Svg/bills.svg",
    title: localesText("payto.bill_payments"),
  },
  {
    id: 2,
    image: "Assets/Svg/subscriptions.svg",
    title: localesText("payto.subscriptions"),
  },
  {
    id: 3,
    image: "Assets/Svg/invoice.svg",
    title: localesText("payto.invoices"),
  },
  {
    id: 4,
    image: "Assets/Svg/loan.svg",
    title: localesText("payto.loans"),
  },
  {
    id: 5,
    image: "Assets/Svg/one_off.svg",
    title: localesText("payto.one_off_purchases"),
  },
];

const SolutionList = () => {
  const isMobile = useMobileScreen();

  return (
    <WrapList
      data={data}
      ItemComponent={Solution}
      itemSize={isMobile ? 325 : 256}
      contentContainerClassName="gap-y-4"
      shoulScale={false}
    />
  );
};

export default SolutionList;
