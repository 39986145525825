import { memo } from "react";

import { SectionWrap } from "Components/Layouts";

import BenefitsList from "./BenefitsList";
import ImageComponent from "./Image.Component";

const Benefits = () => {
  return (
    <SectionWrap
      className="flex items-center justify-center"
      contentContainerProps={{
        className: "flex w-full h-full flex-col items-center gap-14",
      }}
    >
      <ImageComponent />

      {/* <TitleComponent /> */}
      <BenefitsList />
    </SectionWrap>
  );
};

export default memo(Benefits);
