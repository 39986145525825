import { memo } from "react";

import { SectionWrap } from "Components/Layouts";

import TitleComponent from "./Title.Component";

const Description = () => {
  return (
    <SectionWrap
      contentContainerProps={{
        className: "flex w-full flex-col",
      }}
      className="mt-[100px] flex items-center justify-center sm:mt-[150px] md:mt-[210px]"
    >
      <TitleComponent />
    </SectionWrap>
  );
};

export default memo(Description);
