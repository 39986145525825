import { memo } from "react";

import { LottieImage } from "Components/Own";

import LinkCards from "Assets/Lotties/link_cards.json";
import NearByStore from "Assets/Lotties/nearby_store.json";
import PayAndEarn from "Assets/Lotties/pay_and_earn.json";

const Work = () => {
  return (
    <div className="flex flex-col md:flex-row gap-5">
      <LottieImage options={{ animationData: LinkCards }} />
      <LottieImage options={{ animationData: NearByStore }} />
      <LottieImage options={{ animationData: PayAndEarn }} />
    </div>
  );
};

export default memo(Work);
