import { memo } from "react";

import { NextLink } from "Components/Lib/Next";
import { Button, Typography } from "Components/Own";
import { PATHS_NAME } from "Constants/PathName.Constant";
import { classnames } from "Utils/Classnames.Util";
import { generatePath } from "Utils/Common.Util";
import { localesText } from "Utils/LocalesText.Util";
import { formatMoment } from "Utils/Moment.Util";

const TitleComponent = ({
  content,
  date,
  link,
  isReverse,
}: {
  content: string;
  date: string;
  link: string;
  isReverse: boolean;
}) => {
  return (
    <div
      className={classnames("flex flex-1 px-4 md:max-w-[590px]", {
        "md:justify-start": isReverse,
        "md:justify-end": !isReverse,
      })}
    >
      <div className="lg:w-[80%]">
        <Typography.Description className="mb-2 text-clever-description opacity-70 md:mb-4">
          {formatMoment({ date })}
        </Typography.Description>

        <Typography.Heading2 className="font-normal">
          <NextLink
            href={generatePath(PATHS_NAME.CASE_STUDY_DETAIL, {
              case_slug: link,
            })}
          >
            {content}
          </NextLink>
        </Typography.Heading2>

        <NextLink
          href={generatePath(PATHS_NAME.CASE_STUDY_DETAIL, {
            case_slug: link,
          })}
        >
          <Button
            buttonSize="large"
            className="hidden rounded-full lg:mt-6 lg:block"
            wide
            buttonType="primary"
          >
            {localesText("common.read_more")}
          </Button>
        </NextLink>
      </div>
    </div>
  );
};

export default memo(TitleComponent);
