import { useEffect, useState } from "react";

import { ExpandButton, Typography } from "Components/Own";
import { classnames } from "Utils/Classnames.Util";

const CollapseComponent = ({
  index,
  id,
  title,
  content,
  isExpanding,
  onToggle,
}: {
  index: number;
  id: number;
  title: string;
  content: string;
  isExpanding: boolean;
  onToggle: (id: number) => void;
}) => {
  // use for trigger that expand button not change UI, need to click 2 times
  const [_, setIsPlay] = useState(false);

  useEffect(() => {
    if (isExpanding) {
      setIsPlay(true);
    } else {
      setIsPlay(false);
    }
  }, [isExpanding]);

  return (
    <div
      tabIndex={index}
      className={classnames("collapse border-b-[1px] border-b-base-300", {
        "collapse-close": !isExpanding,
      })}
    >
      <input
        type="checkbox"
        className="cursor-pointer"
        checked={isExpanding}
        onChange={() => onToggle(id)}
      />

      <div className="collapse-title px-0 py-6">
        <div className="flex items-center justify-between gap-4 overflow-hidden">
          <Typography.Heading2
            className="flex-1 text-left"
            onClick={() => onToggle(id)}
          >
            {title}
          </Typography.Heading2>
          <ExpandButton
            inputProps={{
              checked: isExpanding,
              onChange: (event) => {
                event.preventDefault();
                event.stopPropagation();
                onToggle(id);
              },
            }}
            containerProps={{ className: "bg-white-slate" }}
          />
        </div>
      </div>
      <div className="collapse-content pl-0">
        <div tabIndex={index} className="py-4">
          <Typography.Body className="text-clever-description">
            {content}
          </Typography.Body>
        </div>
      </div>
    </div>
  );
};

export default CollapseComponent;
