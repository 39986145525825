import { RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";
import { classnames } from "Utils/Classnames.Util";

const TitleComponent = ({ description }: { description: string }) => (
  <div
    className={classnames(
      "flex flex-1",
      "px-6 sm:px-10 md:pl-16",
      "my-8 sm:my-16 md:my-6",
    )}
  >
    <div>
      <Typography.Body className="font-semibold mb-2">
        {localesText("tyro.for_customers")}
      </Typography.Body>
      <div className="flex flex-1 flex-col justify-center gap-5 md:gap-10">
        <Typography.SectionTitle>
          {localesText("tyro.how_it_works")}
          <RotateHighlightText
            content={localesText("tyro.customers")}
            className="ml-2"
          />
        </Typography.SectionTitle>
        <div className="flex flex-1 flex-col gap-3">
          <Typography.Body>{description}</Typography.Body>
        </div>
      </div>
    </div>
  </div>
);

export default TitleComponent;
