import { NextLink } from "Components/Lib/Next";
import { Button, Typography } from "Components/Own";
import { PATHS_NAME } from "Constants/PathName.Constant";
import { localesText } from "Utils/LocalesText.Util";

export default function Contact() {
  return (
    <div className="flex flex-1 flex-col gap-6 md:gap-12 justify-center rounded-[40px] p-6 md:p-10 border">
      <Typography.Heading1>
        {localesText("tyro.join_our_program")}
        <span className="ml-2 text-clever-link font-semibold">
          {localesText("tyro.three_months_access")}
        </span>
      </Typography.Heading1>
      <div className="flex gap-4 flex-col md:flex-row">
        <NextLink
          href={PATHS_NAME.DASHBOARD}
          className="block max-w-56"
          target="_blank"
        >
          <Button
            buttonSize="large"
            className="rounded-full w-full sm:w-[220px]"
            wide
            buttonType="primary"
          >
            {localesText("common.get_started_now")}
          </Button>
        </NextLink>
        <NextLink
          href={PATHS_NAME.GET_IN_TOUCH}
          className="block max-w-56 rounded-[40px] border border-clever-link"
        >
          <Button
            buttonSize="large"
            className="rounded-full w-full sm:w-[220px] text-clever-link bg-transparent"
            wide
          >
            {localesText("common.speak_with_team")}
          </Button>
        </NextLink>
      </div>
    </div>
  );
}
