import { PATHS_NAME } from "Constants/PathName.Constant";
import { useRouter } from "next/router";
import { memo, useEffect } from "react";

import CollapseComponent from "./Collapse.Component";

const Collapses = ({
  data,
  expandingId,
  onToggle,
}: {
  data: {
    id: number;
    title: string;
    content: string;
    href: string;
    lottieData: any;
  }[];
  expandingId: number;
  onToggle: (id: number) => void;
}) => {
  const route = useRouter();
  const currentHash = route.asPath.replace(PATHS_NAME.MERCHANT_API + "#", "");

  useEffect(() => {
    const collapse = document.getElementById(`collapse-id-${currentHash}`);
    const collapseTab = data.find((ele) => ele.href === currentHash);
    if (
      !!collapseTab &&
      !!collapse &&
      collapse.scrollIntoView &&
      typeof collapse.scrollIntoView === "function"
    ) {
      const timeoutId = setTimeout(() => {
        collapse.scrollIntoView({
          behavior: "auto",
          block: "center",
          inline: "center",
        });
        onToggle(collapseTab.id);
        clearTimeout(timeoutId);
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentHash]);

  return (
    <div className="flex-1">
      {data.map((item, index) => {
        return (
          <CollapseComponent
            href={item.href}
            index={index}
            key={item.id.toString()}
            title={item.title}
            content={item.content}
            id={item.id}
            onToggle={onToggle}
            isExpanding={expandingId === item.id}
            lottieData={item.lottieData}
          />
        );
      })}
    </div>
  );
};

export default memo(Collapses);
