import { memo } from "react";

import { SectionWrap, SlateWhite } from "Components/Layouts";
import { LottieImage } from "Components/Own";

import SaveSpend from "Assets/Lotties/save_as_you_spend.json";
import EasyBugeting from "Assets/Lotties/easy-budgeting-cut.json";
import PayAndGetPaid from "Assets/Lotties/pay_and_get_paid.json";

import FirstTitle from "./FirstTitle.Component";
import SecondTitle from "./SecondTitle.Component";
import ThirdTitle from "./ThirdTitle.Component";

const Section3 = () => {
  return (
    <SectionWrap
      containerProps={{ className: "my-8" }}
      contentContainerProps={{
        className: "flex flex-col gap-5",
      }}
      className="flex items-center justify-center"
    >
      <SlateWhite
        containerClassName="px-8 py-12"
        contentContainerClassName="flex flex-col items-center sm:flex-row"
        firstComponent={<FirstTitle />}
        secondComponent={
          <div className="flex h-full max-h-[540px] flex-1">
            <LottieImage options={{ animationData: SaveSpend }} />
          </div>
        }
      />
      <div className="flex flex-col gap-5 md:flex-row">
        <SlateWhite
          containerClassName="px-8 flex-1"
          contentContainerClassName="flex flex-col items-center sm:flex-row-reverse md:flex-col"
          firstComponent={<SecondTitle />}
          secondComponent={
            <div className="flex h-full flex-1 items-end">
              <div className="max-w-[520px]">
                <LottieImage options={{ animationData: EasyBugeting }} />
              </div>
            </div>
          }
        />
        <SlateWhite
          containerClassName="px-8 flex-1"
          contentContainerClassName="flex flex-col items-center sm:flex-row md:flex-col"
          firstComponent={<ThirdTitle />}
          secondComponent={
            <div className="flex h-full flex-1 items-end">
              <LottieImage options={{ animationData: PayAndGetPaid }} />
            </div>
          }
        />
      </div>
    </SectionWrap>
  );
};

export default memo(Section3);
