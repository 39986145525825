import { SlateWhite } from "Components/Layouts";
import { Typography } from "Components/Own";

const Instant = ({
  item,
  itemWidth,
}: {
  item: {
    id: number;
    image: any;
    content: string;
  };
  itemWidth: number;
}) => {
  return (
    <SlateWhite
      containerClassName="flex-1 block rounded-3xl p-6 md:p-8"
      firstComponent={
        <div
          className="flex flex-col overflow-hidden"
          style={{ maxWidth: itemWidth - 64 }}
          suppressHydrationWarning={true}
        >
          <div className="mb-5 flex aspect-square w-[60px] md:mb-6 ">
            <img src={item.image} width={50} height={50} alt="close-icon" />
          </div>
          <Typography.Description className="text-clever-description">
            {item.content}
          </Typography.Description>
        </div>
      }
      secondComponent={null}
    />
  );
};

export default Instant;
