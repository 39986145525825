import { RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";
import { useExpand } from "Hooks/useExpand";

import Collapses from "./Collapses";

export const INDUSTRIES = [
  {
    id: 1,
    title: localesText("payto.fast_secure"),
    content: localesText("payto.fast_secure_description"),
  },
  {
    id: 2,
    title: localesText("payto.settlement"),
    content: localesText("payto.settlement_description"),
  },
];

const TitleComponent = () => {
  const { expandingId, onToggle } = useExpand(1);

  return (
    <div className="flex flex-1 flex-col">
      <div className="text-left md:mb-6">
        <Typography.SectionTitle>
          {localesText("payto.more_efficient")}{" "}
          <RotateHighlightText content={localesText("payto.alternative")} />{" "}
          {localesText("payto.direct_debit")}
        </Typography.SectionTitle>
      </div>

      <Collapses
        expandingId={expandingId}
        onToggle={onToggle}
        data={INDUSTRIES}
      />
    </div>
  );
};

export default TitleComponent;
