import { useEffect } from "react";

import { TRACKING_EVENTS } from "Constants/Event.Constant";
import { logAnalyticEvent } from "Utils/Analytics.Util";
import { checkOS } from "Utils/Common.Util";

import Banner from "./Banner";
import Description from "./Description";
import Benefits from "./Benefits";
import AccountToAccount from "./AccountToAccount";
import InstantCustomer from "./InstantCustomer";
import TakePayto from "./TakePayto";
import MoreEffecient from "./MoreEffecient";
import Solution from "./Solution";
import Launtel from "./Launtel";
import DoBussiness from "./DoBussiness";
import OneClick from "./OneClick";

const PayToPage = ({ slug }: { slug: string }) => {
  useEffect(() => {
    logAnalyticEvent({
      key: TRACKING_EVENTS.payToPageLanded,
      params: { OS: checkOS() },
    });
  }, []);

  return (
    <div className="flex flex-col gap-16 md:gap-20">
      <Banner />
      <Description />
      <Benefits />
      <AccountToAccount />
      <InstantCustomer />
      <OneClick />
      <TakePayto />
      <MoreEffecient />
      <Solution />
      <Launtel slug={slug} />
      <DoBussiness />
      <div />
    </div>
  );
};

export default PayToPage;
