import { memo } from "react";

import { RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

const TitleComponent = () => {
  return (
    <div className="max-w-[720px] flex-1 text-center">
      <Typography.SectionTitle>
        {localesText("about.who_is_behind")}
      </Typography.SectionTitle>
      <Typography.SectionTitle className="mb-8">
        <RotateHighlightText content={localesText("common.hello_clever")} />
      </Typography.SectionTitle>

      <Typography.Body className="mb-4 text-clever-description">
        {localesText("about.made_of_ex_bankers")}
      </Typography.Body>

      <Typography.Body className="text-clever-description">
        {localesText("about.more_than_just_words")}
      </Typography.Body>
    </div>
  );
};

export default memo(TitleComponent);
