import { memo } from "react";

import { LottieImage, RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";
import { LOTTIE_URLS } from "Constants/URL.Constant";

const ImageComponent = () => (
  <div className="h-full flex-1 text-center">
    <div className="text-left">
      <Typography.SectionTitle>
        <RotateHighlightText content={localesText("xero.faqs")} />
      </Typography.SectionTitle>
    </div>

    <LottieImage
      options={{ animationData: "", url: LOTTIE_URLS.FAQ }}
      containerClassName={"h-auto md:h-[500px]"}
    />
  </div>
);

export default memo(ImageComponent);
