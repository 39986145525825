import { SectionWrap } from "Components/Layouts";
import BenefitsList from "./BenifitList";

const Section2 = () => {
  return (
    <SectionWrap
      containerProps={{
        className: "mt-[120px] md:mt-[180px]",
      }}
      className="justyfi-center flex items-center"
      contentContainerProps={{
        className: "w-full flex justify-center items-center",
      }}
    >
      <BenefitsList />
    </SectionWrap>
  );
};

export default Section2;
