import { memo } from "react";

import { SectionWrap } from "Components/Layouts";

import TitleComponent from "./Title.Component";
import DocumentAPI from "./DocumentAPI";
import PlatFormList from "./PartnerList";

const Section3 = () => {
  return (
    <SectionWrap
      containerProps={{
        className: "pt-12 pb-8",
      }}
      contentContainerProps={{
        className: "flex flex-col items-center",
      }}
      className="flex justify-center"
    >
      <TitleComponent />
      <DocumentAPI />
      <PlatFormList />
    </SectionWrap>
  );
};

export default Section3;
