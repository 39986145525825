import { memo } from "react";

import { SectionWrap } from "Components/Layouts";

import TitleComponent from "./Title.Component";
import WorkWithUs from "./WorkWithUs";

const Section2 = () => {
  return (
    <SectionWrap
      containerProps={{
        className: "mt-[250px] flex justify-center my-8",
      }}
      contentContainerProps={{
        className: "flex flex-col items-center",
      }}
      className="flex items-center justify-center"
    >
      <TitleComponent />
      <WorkWithUs />
    </SectionWrap>
  );
};

export default memo(Section2);
