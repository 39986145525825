import { SectionWrap } from "Components/Layouts";
import { MAX_INNER_CONTENT_WIDTH } from "Constants/Common.Constant";

import PostItem from "./PostItem";

const Section2 = ({ blogs }: { blogs: any[] }) => {
  return (
    <SectionWrap
      className="flex justify-center"
      contentContainerProps={{
        className:
          "flex flex-col items-center justify-center gap-8 lg:gap-12 py-12",
      }}
    >
      {blogs.map((blog, index) => {
        return (
          <PostItem
            key={index.toString()}
            data={blog}
            isReverse={index % 2 !== 0}
          />
        );
      })}
    </SectionWrap>
  );
};

export default Section2;
