import { memo } from "react";

import { NextLink } from "Components/Lib/Next";
import { Button } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";
import { CAREERS } from "Constants/URL.Constant";

const WorkWithUs = ({ buttonSize = "normal" }: { buttonSize?: any }) => {
  return (
    <NextLink href={CAREERS} target="_blank">
      <Button
        buttonSize={buttonSize}
        className="rounded-full"
        wide
        buttonType="primary"
      >
        {localesText("about.work_with_us")}
      </Button>
    </NextLink>
  );
};

export default memo(WorkWithUs);
