import { memo } from "react";

import { LottieImage } from "Components/Own";
import { LOTTIE_URLS } from "Constants/URL.Constant";

const ImageComponent = () => {
  return (
    <div className="flex flex-1 items-center justify-center ">
      <div className="max-w-[430px] md:w-full">
        <LottieImage
          options={{ animationData: "", url: LOTTIE_URLS.REFER_FRIEND }}
        />
      </div>
    </div>
  );
};

export default memo(ImageComponent);
