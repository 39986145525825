import { RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

export default function TitleComponent() {
  return (
    <div className="flex flex-col items-center mb-10 md:mb-20">
      <Typography.Heading3 className="text-center mb-4 font-semibold">
        {localesText("tyro.in_store_instant")}
      </Typography.Heading3>
      <Typography.Giant className="text-center text-dark mb-2">
        <RotateHighlightText content={localesText("tyro.reward")} />

        {localesText("tyro.your_regulars")}
      </Typography.Giant>
      <Typography.Heading1 className="text-center">
        {localesText("tyro.offer_instant")}
      </Typography.Heading1>
    </div>
  );
}
