import { memo } from "react";

import { Button, RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";
import { _toLower } from "Utils/Lodash.Util";
import { NextLink } from "Components/Lib/Next";
import { generatePath } from "Utils/Common.Util";
import { PATHS_NAME } from "Constants/PathName.Constant";
import { CASE_STUDY_SLUG } from "Constants/Common.Constant";

const TitleComponent = ({ slug }: { slug: string }) => (
  <div className="flex flex-1 flex-col text-center md:text-left">
    <Typography.SectionTitle className="mb-2">
      {localesText("merchant_api.case_study")}
    </Typography.SectionTitle>

    <Typography.SectionTitle className="mb-8">
      <RotateHighlightText content={localesText("common.launtel")} />
    </Typography.SectionTitle>

    <Typography.Body className="mb-6 text-clever-description md:mb-12">
      {localesText("merchant_api.how_launtel")}
    </Typography.Body>

    <NextLink
      href={generatePath(PATHS_NAME.CASE_STUDY_DETAIL, {
        case_slug: slug || CASE_STUDY_SLUG,
      })}
      target="_blank"
      prefetch
    >
      <Button
        buttonSize="large"
        className="mb-6 rounded-full md:mb-12"
        wide
        buttonType="primary"
      >
        {localesText("common.find_out_more")}
      </Button>
    </NextLink>
  </div>
);

export default memo(TitleComponent);
