import { RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

export default function TitleComponent() {
  return (
    <div className="flex flex-col justify-center items-center gap-6">
      <Typography.SectionTitle className="text-center">
        <RotateHighlightText
          content={localesText("tyro.boost")}
          className="mr-2"
        />
        {localesText("tyro.revenue_with_rewarding")}
      </Typography.SectionTitle>
      <div className="max-w-[880px] text-center">
        <Typography.Content className="text-clever-description">
          {localesText("tyro.increase_sales")}
        </Typography.Content>
      </div>
    </div>
  );
}
