import { SectionWrap } from "Components/Layouts";

import TitleComponent from "./Title.Component";
import Collapses from "./Collapses";

const Section7 = () => {
  return (
    <SectionWrap
      containerProps={{
        className: "mt-12 mb-24 flex justify-center bg-white-slate py-16",
      }}
      className="flex justify-center"
      contentContainerProps={{
        className: "flex justify-center gap-10 w-full flex-col lg:flex-row",
      }}
    >
      <TitleComponent />
      <Collapses />
    </SectionWrap>
  );
};

export default Section7;
