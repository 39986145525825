import { SectionWrap } from "Components/Layouts";

import TitleComponent from "./Title.Component";
import ImageComponent from "./Image.Component";

const Section8 = () => {
  return (
    <SectionWrap
      containerProps={{
        className: "my-8 flex justify-center",
      }}
      className="flex justify-center"
      contentContainerProps={{
        className:
          "flex flex-col justify-center items-center overflow-hidden rounded-[40px] bg-white-slate pt-16 w-full",
      }}
    >
      <TitleComponent />
      <ImageComponent />
    </SectionWrap>
  );
};

export default Section8;
