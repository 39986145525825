import { useEffect } from "react";

import { logAnalyticEvent } from "Utils/Analytics.Util";
import { TRACKING_EVENTS } from "Constants/Event.Constant";
import { localesText } from "Utils/LocalesText.Util";
import { useExpand } from "Hooks/useExpand";

import CollapseComponent from "./Collapse.Component";

const data = [
  {
    id: 1,
    title: localesText("xero.how_does"),
    content: localesText("xero.faq_text_one"),
  },
  {
    id: 2,
    title: localesText("xero.what_payment"),
    content: localesText("xero.faq_text_two"),
  },
  {
    id: 3,
    title: localesText("xero.what_is_npp"),
    content: localesText("xero.faq_text_three"),
  },
  {
    id: 4,
    title: localesText("xero.what_is_payId"),
    content: localesText("xero.payid_text_one"),
    second_content: localesText("xero.payid_text_two"),
    link_content: localesText("xero.learn_more_here"),
  },
  {
    id: 5,
    title: localesText("xero.when_will_receive"),
    content: localesText("xero.faq_text_five"),
  },
];

const Collapses = () => {
  const { expandingId, onToggle } = useExpand();
  const lastIndex = data.length - 1;

  useEffect(() => {
    if (expandingId) {
      logAnalyticEvent({ key: TRACKING_EVENTS.faqViewed });
    }
  }, [expandingId]);

  return (
    <div className="flex-1">
      {data.map((item, index) => {
        return (
          <CollapseComponent
            index={index}
            lastIndex={lastIndex}
            key={item.id.toString()}
            title={item.title}
            content={item.content}
            second_content={item.second_content}
            link_content={item.link_content}
            id={item.id}
            onToggle={onToggle}
            isExpanding={expandingId === item.id}
          />
        );
      })}
    </div>
  );
};

export default Collapses;
