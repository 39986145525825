import { memo } from "react";

import { LottieImage } from "Components/Own";
import { LOTTIE_URLS } from "Constants/URL.Constant";

const ImageComponent = () => (
  <div className="flex flex-1 items-center justify-center">
    <div className="relative w-full max-w-[530px]">
      <LottieImage
        options={{ animationData: "", url: LOTTIE_URLS.REDUCE_FRAUD }}
      />
    </div>
  </div>
);

export default memo(ImageComponent);
