import { useState, useEffect } from "react";

import { InstantDealProps } from "Types";
import { getStoresCount } from "Services";

import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";

const Shops = ({ instantDeals }: { instantDeals: InstantDealProps[] }) => {
  const [storeCount, setStoreCount] = useState<number>(0);

  useEffect(() => {
    const fetchStoreCount = async () => {
      const response = await getStoresCount();
      setStoreCount(response.storeCount);
    };
    fetchStoreCount();
  }, []);

  return (
    <div>
      <Section1 storeCount={storeCount} />
      {/* Will add filter by category here */}
      {!!instantDeals && !!instantDeals.length && (
        <Section2 instantDeals={instantDeals} />
      )}
      <Section3 storeCount={storeCount} />
      <div className="h-8" />
    </div>
  );
};

export default Shops;
