import { WrapList } from "Components/Own";
import Platform from "./Partner.Component";

import pay_id from "Assets/Images/Partners/pay_id.png";
import pay_to from "Assets/Images/Partners/pay_to.png";
import visa from "Assets/Images/Partners/visa.png";
import mastercard from "Assets/Images/Partners/mastercard.png";
import american_express from "Assets/Images/Partners/american_express.png";
import commonwealth from "Assets/Images/Partners/commonwealth.png";
import westpac from "Assets/Images/Partners/westpac.png";
import nab from "Assets/Images/Partners/nab.png";
import anz from "Assets/Images/Partners/anz.png";
import banks from "Assets/Images/Partners/70banks.png";
import { useMobileScreen } from "Hooks/useMediaQuery";

const PlatForms = [
  {
    id: 1,
    image: pay_id,
    alt: "pay_id",
    width: 68,
  },
  {
    id: 2,
    image: pay_to,
    alt: "pay_to",
    width: 68,
  },
  {
    id: 3,
    image: visa,
    alt: "visa",
    width: 86,
  },
  {
    id: 4,
    image: mastercard,
    alt: "mastercard",
    width: 70,
  },
  {
    id: 5,
    image: american_express,
    alt: "american_express",
    width: 55,
  },
  {
    id: 6,
    image: commonwealth,
    alt: "commonwealth",
    width: 141,
  },
  {
    id: 7,
    image: westpac,
    alt: "westpac",
    width: 149,
  },
  {
    id: 8,
    image: nab,
    alt: "nab",
    width: 83,
  },
  {
    id: 9,
    image: anz,
    alt: "anz",
    width: 95,
  },
  {
    id: 9,
    image: banks,
    alt: "70banks",
    width: 124,
  },
];

const PlatFormList = () => {
  const isMobile = useMobileScreen();

  return (
    <WrapList
      data={PlatForms}
      ItemComponent={Platform}
      itemSize={isMobile ? 178 : 256}
      needFill={false}
    />
  );
};

export default PlatFormList;
