import { memo } from "react";

import { NormalImage } from "Components/Lib";

import PaytoDescription from "Assets/Images/Others/payto_description.png";

const Benefits = () => {
  return (
    <div className="relative flex max-h-[800px]">
      <div className="absolute inset-0 my-8 flex justify-center"></div>
      <NormalImage
        src={PaytoDescription.src}
        alt="flower-mobile"
        width={700}
        height={700}
        sizes="80vw"
      />
    </div>
  );
};

export default memo(Benefits);
