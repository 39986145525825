import { memo } from "react";

import AccountToAccount from "Assets/Images/Others/payto_account_to_account.png";
import { NormalImage } from "Components/Lib";

const ImageComponent = () => {
  return (
    <div className="flex max-h-[600px] w-full flex-1 justify-start">
      <NormalImage
        src={AccountToAccount.src}
        alt="account-to-account"
        className="h-full max-h-[600px] w-full max-w-[1000px]"
        width={1000}
        height={600}
      />
    </div>
  );
};

export default memo(ImageComponent);
