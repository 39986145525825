import { SlateWhite } from "Components/Layouts";
import { Typography } from "Components/Own";

const Benefit = ({
  item,
  itemWidth,
}: {
  item: {
    id: number;
    image: any;
    title: string;
    content: string;
  };
  itemWidth: number;
}) => {
  return (
    <SlateWhite
      containerClassName="p-6 sm:p-8"
      firstComponent={
        <div
          className="flex flex-col overflow-hidden"
          style={{ maxWidth: itemWidth - 64 }}
          suppressHydrationWarning={true}
        >
          <div className="mb-4 flex aspect-square w-[60px] md:mb-6">
            <img src={item.image} width={50} height={50} alt="close-icon" />
          </div>

          <Typography.Body className="min-h-8 text-xl font-normal sm:h-[55px] sm:w-[80%] sm:text-2xl mb-5 md:mb-0">
            {item.title}
          </Typography.Body>
          <Typography.Description className="text-clever-description">
            {item.content}
          </Typography.Description>
        </div>
      }
      secondComponent={null}
    />
  );
};

export default Benefit;
