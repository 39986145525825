import { memo } from "react";

import { RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

const TitleComponent = () => {
  return (
    <div className="relative mb-8 flex-1">
      <div className="lg:absolute lg:-right-28 lg:left-0">
        <Typography.Giant className="text-center text-white lg:text-left">
          {localesText("about.empowering")}
        </Typography.Giant>
        <Typography.Giant className="mb-6 text-center text-white lg:text-left">
          <RotateHighlightText content={localesText("about.everyone")} />
        </Typography.Giant>

        <Typography.Content className="text-center text-white lg:text-left">
          {localesText("about.about_empowering")}
        </Typography.Content>
      </div>
    </div>
  );
};

export default memo(TitleComponent);
