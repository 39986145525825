import { memo } from "react";

import { RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

const TitleComponent = () => {
  return (
    <div className="flex flex-1 flex-col justify-center">
      <div className="mb-6 text-left">
        <Typography.SectionTitle>
          {localesText("in_store.tap_and_get")}

          <RotateHighlightText content={localesText("common.cashback")} />
          {localesText("in_store.that_easy")}
        </Typography.SectionTitle>
      </div>

      <Typography.Body className="mb-6 text-clever-description sm:pr-4 md:pr-11">
        {localesText("in_store.once_your_card")}
      </Typography.Body>
      <Typography.Body className="mb-6 text-clever-description sm:pr-4 md:pr-11">
        {localesText("in_store.no_complicated")}
      </Typography.Body>
    </div>
  );
};

export default memo(TitleComponent);
