import { InstantDealProps } from "Types";

import Section2 from "./Section2";

const ShopDetail = ({ data }: { data: InstantDealProps }) => {
  return (
    <div>
      <Section2 data={data} />
    </div>
  );
};

export default ShopDetail;
