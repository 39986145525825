import { RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";
import { _toLower } from "Utils/Lodash.Util";

const TitleComponent = () => (
  <div className="flex flex-1 flex-col justify-center">
    <div className="mb-4 text-center font-normal md:text-left">
      <Typography.SectionTitle>
        {localesText("buy_to_earn.we_work_with")}{" "}
      </Typography.SectionTitle>
      <Typography.SectionTitle>
        {localesText("buy_to_earn.all")}{" "}
        <RotateHighlightText
          content={_toLower(localesText("common.e_commerce"))}
        />
      </Typography.SectionTitle>
      <Typography.SectionTitle>
        {localesText("buy_to_earn.verticals")}
      </Typography.SectionTitle>
    </div>

    <Typography.Body className="text-center text-clever-description sm:pr-4 md:w-[75%] md:px-0 md:text-left">
      {localesText("buy_to_earn.from_fashion")}
    </Typography.Body>
  </div>
);

export default TitleComponent;
