import { WrapList } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";
import { useMobileScreen } from "Hooks/useMediaQuery";
import { LOTTIE_URLS } from "Constants/URL.Constant";

import Benefit from "./Benefit.Component";

const data = [
  {
    id: 1,
    lottie: LOTTIE_URLS.INSTANT_CUSTOMER,
    title: localesText("buy_to_earn.instant_customer"),
    content: localesText("buy_to_earn.instant_customer_description"),
  },
  {
    id: 2,
    lottie: LOTTIE_URLS.REACH_NEW_CUSTOMER,
    title: localesText("buy_to_earn.reach_new_customers"),
    content: localesText("buy_to_earn.reach_new_customers_description"),
  },
  {
    id: 3,
    lottie: LOTTIE_URLS.MARKETING_ASSETS,
    title: localesText("buy_to_earn.marketing_assets"),
    content: localesText("buy_to_earn.marketing_assets_description"),
  },
  {
    id: 4,
    lottie: LOTTIE_URLS.INCREASE_BASKET,
    alt: "benefit",
    title: localesText("buy_to_earn.increase_basket"),
    content: localesText("buy_to_earn.increase_basket_description"),
  },
  {
    id: 5,
    lottie: LOTTIE_URLS.REDUCE_CART,
    alt: "benefit",
    title: localesText("buy_to_earn.reduce_cart"),
    content: localesText("buy_to_earn.reduce_cart_description"),
  },
];

const Benefits = () => {
  const isMobile = useMobileScreen();

  return (
    <WrapList
      containerClassName="pt-12"
      data={data}
      ItemComponent={Benefit}
      itemSize={isMobile ? 340 : 256}
      shoulScale={false}
      contentContainerClassName="gap-y-10"
    />
  );
};

export default Benefits;
