import { SectionWrap, SlateWhite } from "Components/Layouts";

import { LottieImage } from "Components/Own";
import { LOTTIE_URLS } from "Constants/URL.Constant";

import FirstTitle from "./FirstTitle.Component";
import SecondTitle from "./SecondTitle.Component";

export default function Section7() {
  return (
    <SectionWrap
      containerProps={{
        className: "my-8",
      }}
      contentContainerProps={{
        className: "flex flex-col gap-5 w-full",
      }}
      className="flex items-center justify-center"
    >
      <div className="flex w-full flex-col gap-5 md:flex-row">
        <SlateWhite
          containerClassName="px-8 flex-1"
          contentContainerClassName="flex flex-col items-center sm:flex-row-reverse md:flex-col gap-3 md:gap-8"
          firstComponent={<FirstTitle />}
          secondComponent={
            <div className="flex h-full flex-1 items-end">
              <div className=" max-w-[540px]">
                <LottieImage
                  options={{ animationData: "", url: LOTTIE_URLS.FIND_US_ONE }}
                />
              </div>
            </div>
          }
        />
        <SlateWhite
          containerClassName="px-8 flex-1"
          contentContainerClassName="flex flex-col items-center sm:flex-row md:flex-col gap-3 md:gap-8"
          firstComponent={<SecondTitle />}
          secondComponent={
            <div className="flex h-full flex-1 items-end">
              <div className=" max-w-[540px]">
                <LottieImage
                  options={{ animationData: "", url: LOTTIE_URLS.FIND_US_TWO }}
                />
              </div>
            </div>
          }
        />
      </div>
    </SectionWrap>
  );
}
