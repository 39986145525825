import { SectionWrap, SlateWhite } from "Components/Layouts";
import { NextImage } from "Components/Lib/Next";
import { InstantDealProps } from "Types";

import ShopInfo from "./ShopInfo.Component";
import DownloadApp from "./DownloadApp.Component";

import shopDetail from "Assets/Images/Others/shop_detail_flower_mobile.png";

const Section2 = ({ data }: { data: InstantDealProps }) => {
  return (
    <SectionWrap
      containerProps={{
        className: "mt-12 mb-20",
      }}
      contentContainerProps={{
        className: "flex justify-center gap-5 max-w-[1200px]",
      }}
      className="flex justify-center"
    >
      <div className="flex flex-1 flex-col gap-8 md:flex-row">
        <ShopInfo data={data} />

        <SlateWhite
          containerClassName="px-8 flex-1 max-h-[700px]"
          contentContainerClassName="flex flex-col items-center sm:flex-row md:flex-col"
          firstComponent={<DownloadApp />}
          secondComponent={
            <div className="flex h-full flex-1 items-end">
              <NextImage
                src={shopDetail}
                alt="shop_detail"
                width={434}
                height={540}
                scaleForSmallDevice={false}
              />
            </div>
          }
        />
      </div>
    </SectionWrap>
  );
};

export default Section2;
