import { memo } from "react";

import { SectionWrap } from "Components/Layouts";

import TitleComponent from "./Title.Component";
import ImageComponent from "./Image.Component";

const OneClick = () => {
  return (
    <SectionWrap
      contentContainerProps={{
        className: "flex flex-col gap-10 md:gap-6 md:flex-row",
      }}
      className="flex items-center justify-center"
    >
      <ImageComponent />
      <TitleComponent />
    </SectionWrap>
  );
};

export default memo(OneClick);
