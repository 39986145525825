import { memo } from "react";

import CollapseComponent from "./Collapse.Component";

const Collapses = ({
  data,
  expandingId,
  onToggle,
}: {
  data: {
    id: number;
    title: string;
    content: string;
  }[];
  expandingId: number;
  onToggle: (id: number) => void;
}) => {
  return (
    <div className="flex-1">
      {data.map((item, index) => {
        return (
          <CollapseComponent
            key={item.id.toString()}
            index={index}
            id={item.id}
            title={item.title}
            content={item.content}
            onToggle={onToggle}
            isExpanding={expandingId === item.id}
          />
        );
      })}
    </div>
  );
};

export default memo(Collapses);
