import { memo } from "react";

import { RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";
import { _toLower } from "Utils/Lodash.Util";

const TitleComponent = () => (
  <div className="h-full flex-1 text-center lg:pt-12">
    <Typography.SectionTitle>
      {localesText("common.merchant")}{" "}
      <RotateHighlightText content={localesText("common.faqs")} />
    </Typography.SectionTitle>
  </div>
);

export default memo(TitleComponent);
