import { memo } from "react";

import { NormalImage } from "Components/Lib";
import { LottieImage } from "Components/Own";
import { classnames } from "Utils/Classnames.Util";
import { LOTTIE_URLS } from "Constants/URL.Constant";

import Effecient from "Assets/Images/Others/payto_effecient.png";

const ImageComponent = () => (
  <div className="relative flex flex-1 items-center justify-center">
    <div
      className={classnames(
        "absolute bottom-[15%] left-[5%] mx-8 flex max-h-[90px] max-w-[400px]",
        "sm:bottom-[15%] sm:left-[10%] sm:mx-24",
        "md:bottom-[22%] md:left-[5%] md:mx-8",
        "lg:bottom-[15%] lg:left-[10%]"
      )}
    >
      <LottieImage
        width="auto"
        height="auto"
        options={{ animationData: "", url: LOTTIE_URLS.ALTERNATIVE }}
      />
    </div>
    <div className="w-full max-w-[530px]">
      <NormalImage src={Effecient.src} width={540} height={540} />
    </div>
  </div>
);

export default memo(ImageComponent);
