import { WrapList } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

import Benifit from "./Benifit.Component";

export default function BenefitsList() {
  const data = [
    {
      id: 1,
      image: "Assets/Svg/optimize.svg",
      title: localesText("xero.optimse_cashflow"),
      content: localesText("xero.receive_customer"),
    },
    {
      id: 2,
      image: "Assets/Svg/integration.svg",
      title: localesText("xero.eliminate"),
      content: localesText("xero.streamline"),
    },
    {
      id: 3,
      image: "Assets/Svg/lightning.svg",
      alt: "benefit",
      title: localesText("xero.offer"),
      content: localesText("xero.get_paid_real_time"),
    },
  ];

  return (
    <WrapList
      data={data}
      ItemComponent={Benifit}
      itemSize={440}
      fillNumber={0}
      shoulScale={false}
      contentContainerClassName="gap-y-6"
    />
  );
}
