import { memo } from "react";

import { SectionWrap } from "Components/Layouts";

import TitleComponent from "./Title.Component";
import AboutUsImage from "./AboutUsImage.Component";

const Section1 = () => {
  return (
    <SectionWrap
      containerProps={{
        className: "bg-clever-primary",
      }}
      contentContainerProps={{
        className:
          "-mb-[250px] flex w-full flex-col items-center lg:flex-row lg:items-start",
      }}
      className="flex items-center justify-center pt-8 pb-16"
    >
      <TitleComponent />
      <AboutUsImage />
    </SectionWrap>
  );
};

export default memo(Section1);
