import { memo } from "react";

import { NormalImage } from "Components/Lib";
import { NextLink } from "Components/Lib/Next";
import { Typography } from "Components/Own";
import { PATHS_NAME } from "Constants/PathName.Constant";
import { generatePath, getBlogImageSize } from "Utils/Common.Util";
import { formatMoment } from "Utils/Moment.Util";

const BlogItem = ({ item, itemWidth }: { item: any; itemWidth: number }) => {
  const imageUrls = getBlogImageSize(item);

  return (
    <div style={{ width: itemWidth }} suppressHydrationWarning={true}>
      <NextLink
        href={generatePath(PATHS_NAME.BLOG_DETAIL, {
          blog_slug: item.slug,
        })}
      >
        <div
          className="relative overflow-hidden rounded-3xl bg-gray-50"
          style={{ width: itemWidth, height: 0.61 * itemWidth }}
          suppressHydrationWarning={true}
        >
          <NormalImage
            src={imageUrls.medium || imageUrls.original}
            alt={item.title.rendered}
            className="mx-auto h-full w-full object-cover object-center"
          />
        </div>
        {!!item.date && (
          <Typography.Small className="mt-2 text-clever-description">
            {formatMoment({ date: item.date })}
          </Typography.Small>
        )}
        <Typography.Heading3 className="three-line-clamp mt-2 overflow-hidden">
          {item?.title?.rendered || ""}
        </Typography.Heading3>
      </NextLink>
    </div>
  );
};

export default memo(BlogItem);
