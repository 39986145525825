import { memo } from "react";

import { Button } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";
import { NextLink } from "Components/Lib/Next";
import { CAREERS } from "Constants/URL.Constant";

const WorkWithUs = () => {
  return (
    <NextLink href={CAREERS} target="_blank">
      <Button
        buttonSize="large"
        className="my-8 rounded-full"
        wide
        buttonType="primary"
      >
        {localesText("about.work_with_us")}
      </Button>
    </NextLink>
  );
};

export default memo(WorkWithUs);
