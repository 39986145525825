import { RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

const TitleComponent = () => (
  <div className="flex flex-col items-center text-center">
    <Typography.SectionTitle>
      {localesText("tyro.solution_for_your")}
      <br />
      <RotateHighlightText content={localesText("tyro.industry")} />
    </Typography.SectionTitle>
  </div>
);

export default TitleComponent;
