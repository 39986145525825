import { RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

export default function TitleComponent() {
  return (
    <div className="flex flex-col justify-center items-center gap-6">
      <Typography.SectionTitle className="text-center">
        {localesText("in_store.hear_from_our")}
        <RotateHighlightText
          content={localesText("in_store.clever_customers")}
          className="mr-2"
        />
      </Typography.SectionTitle>
      <div className="max-w-[480px] text-center">
        <Typography.Content className="text-clever-description">
          {localesText("in_store.hundreds_of_aussies")}
        </Typography.Content>
      </div>
    </div>
  );
}
