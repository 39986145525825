import { memo } from "react";

import { logAnalyticEvent } from "Utils/Analytics.Util";
import { TRACKING_EVENTS } from "Constants/Event.Constant";
import { Button } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";
import { NextLink } from "Components/Lib/Next";
import { PATHS_NAME } from "Constants/PathName.Constant";

const ShowMoreDeals = ({ buttonSize = "normal" }: { buttonSize?: any }) => {
  const onClickShowMore = () => {
    logAnalyticEvent({ key: TRACKING_EVENTS.seeMoreDealClicked });
  };

  return (
    <NextLink
      href={PATHS_NAME.SHOPS}
      className="self-start"
      onClick={onClickShowMore}
    >
      <Button
        className="mt-4 rounded-full px-7"
        buttonSize={buttonSize}
        buttonType="primary"
      >
        {localesText("common.show_more_deals")}
      </Button>
    </NextLink>
  );
};

export default memo(ShowMoreDeals);
