import { SectionWrap } from "Components/Layouts";

import InstantCashback from "./InstantCashback.Component";
import Contact from "./Contact.Component";

export default function Section14() {
  return (
    <SectionWrap
      containerProps={{
        className: "mb-20 md:mb-[120px]",
      }}
      contentContainerProps={{
        className: "flex flex-col flex-1 gap-5",
      }}
      className="flex items-center justify-center"
    >
      <div className="flex flex-col gap-5 md:flex-row">
        <InstantCashback />
        <Contact />
      </div>
    </SectionWrap>
  );
}
