import { SectionWrap, SlateWhite } from "Components/Layouts";
import { NextImage } from "Components/Lib/Next";
import getMoreDealsImg from "Assets/Images/Others/get-more-deal.png";

import TitleComponent from "./Title.Component";

const Section3 = ({ storeCount }: { storeCount: number }) => {
  return (
    <SectionWrap
      containerProps={{ className: "py-8 flex" }}
      className="flex justify-center"
      contentContainerProps={{ style: { maxWidth: 1200, width: "100%" } }}
    >
      <SlateWhite
        containerClassName="px-[40px] pt-12 w-full"
        contentContainerClassName="flex flex-col items-center lg:flex-row w-full gap-5"
        firstComponent={<TitleComponent storeCount={storeCount} />}
        secondComponent={
          <div className="flex h-full flex-1 items-end justify-center">
            <NextImage
              src={getMoreDealsImg}
              alt="save-spend"
              width={343}
              height={411}
            />
          </div>
        }
      />
    </SectionWrap>
  );
};

export default Section3;
