import { memo, useEffect, useRef } from "react";

import { useIsInViewport } from "Hooks/useIsInViewPort";
import { HOW_WORKS_TYPE } from "Types";
import { localesText } from "Utils/LocalesText.Util";

import ReferSlider from "Assets/Images/Others/xero_slider_three.png";

import { NormalImage } from "Components/Lib";
import Title from "../Title.Component";

const PayToTab = ({
  tabName,
  isActive,
  onTabInViewPort,
  paginatorScroll,
}: {
  tabName: HOW_WORKS_TYPE;
  isActive: boolean;
  onTabInViewPort: (name: HOW_WORKS_TYPE) => void;
  paginatorScroll: (id: string) => void;
}) => {
  const ref = useRef<any>(null);

  const isInViewport = useIsInViewport(ref);

  useEffect(() => {
    if (isInViewport && !isActive) {
      onTabInViewPort(tabName);
      paginatorScroll("tab-" + tabName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive, isInViewport]);

  return (
    <>
      <Title
        title={localesText("xero.step_three")}
        description={localesText("xero.it_work_text_three")}
      />
      <div ref={ref} className="h-3 w-3" />
      <div className="flex flex-1 items-end px-5 md:pl-0 md:pr-4">
        {/* <LottieImage options={{ animationData: XeroThree }} /> */}
        <NormalImage src={ReferSlider.src} width={710} height={745} />
      </div>
    </>
  );
};

export default memo(PayToTab);
