import { RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

const TitleComponent = () => {
  return (
    <div className="flex flex-1 flex-col justify-center">
      <div className="mb-10">
        <img
          src={"Assets/Svg/payto.svg"}
          width={110}
          height={45}
          alt="close-icon"
        />
      </div>
      <div className="mb-6 text-left">
        <Typography.SectionTitle>
          {localesText("xero.recurring")}
          <RotateHighlightText
            content={localesText("xero.real_time")}
            className="ml-3 inline-block"
          />
          <br />
          {localesText("xero.bank_payments")}
        </Typography.SectionTitle>
      </div>

      <Typography.Body className="mb-6 text-clever-description sm:pr-4 md:pr-11">
        {localesText("xero.bank_payments_text_one")}
      </Typography.Body>
      <Typography.Body className="text-clever-description sm:pr-4 md:pr-11">
        {localesText("xero.bank_payments_text_two")}
      </Typography.Body>
    </div>
  );
};

export default TitleComponent;
