import { memo } from "react";
import { classnames } from "Utils/Classnames.Util";

import { parseHtml } from "Utils/ParseHTML.Util";
import classes from "./BlogContent.module.scss";

const BlogContent = ({ content = "" }: { content: string }) => {
  return (
    <div className="-mt-7 flex w-full flex-1 items-start justify-center mb-10">
      <div
        className={classnames(
          "w-full md:max-w-[700px]",
          classes["blog-content-container"]
        )}
      >
        {parseHtml(content || "")}
      </div>
    </div>
  );
};

export default memo(BlogContent);
