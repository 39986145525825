import { memo } from "react";

import { RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

const TitleComponent = () => (
  <div className="flex flex-1 flex-col pb-10 pt-16 text-center">
    <div className="mb-6">
      <Typography.SectionTitle>
        {localesText("common.easy")}{" "}
        <RotateHighlightText content={localesText("common.budgeting")} />
      </Typography.SectionTitle>
    </div>

    <Typography.Body className="text-clever-description sm:pr-4">
      {localesText("home.no_more_jaw_dropping")}
    </Typography.Body>
  </div>
);

export default memo(TitleComponent);
