import moment from "moment";

export const DEFAULT_BE_FORMAT = "DD-MM-YYYY";
export const DEFAULT_FE_FORMAT = "MMMM DD, YYYY";

export const formatDate = ({
  date,
  format,
}: {
  date: string | any;
  format: string;
}) => {
  if (!date) return null;
  return moment(date, DEFAULT_BE_FORMAT).format(format || DEFAULT_FE_FORMAT);
};

export const formatMoment = ({
  date,
  format,
}: {
  date: string | any;
  format?: string;
}) => {
  if (format) {
    return moment(date).format(format);
  }
  return moment(date).format(DEFAULT_FE_FORMAT);
};

export const jsDateConvert = (value: any, format: string) => {
  if (format) {
    return moment(value, format).toDate();
  }

  return moment(value).toDate();
};

export default moment;
