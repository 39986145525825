import { WrapList } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";
import { useMobileScreen } from "Hooks/useMediaQuery";

import Instant from "./Instant.Component";

export default function InstantList() {
  const isMobile = useMobileScreen();

  const data = [
    {
      id: 1,
      image: "Assets/Svg/register.svg",
      title: localesText("tyro.register"),
      content: localesText("tyro.sign_up_business"),
    },
    {
      id: 2,
      image: "Assets/Svg/integration_green.svg",
      title: localesText("tyro.integration"),
      content: localesText("tyro.connect_tyro"),
    },
    {
      id: 3,
      image: "Assets/Svg/photo.svg",
      title: localesText("tyro.provide_materials"),
      content: localesText("tyro.we_provide"),
    },
    {
      id: 4,
      image: "Assets/Svg/star.svg",
      title: localesText("tyro.ready_reward"),
      content: localesText("tyro.cashback_your_pos"),
    },
  ];

  return (
    <WrapList
      data={data}
      ItemComponent={Instant}
      itemSize={isMobile ? 340 : 310}
      shoulScale={false}
      contentContainerClassName="gap-y-10"
      fillNumber={0}
    />
  );
}
