import Script from "next/script";

import SEOHead from "./SEOHead.Component";

const MainLayout = ({
  children,
  title,
  description,
  metaImage,
}: {
  title?: string;
  description?: string;
  metaImage?: any;
  children: any;
  hasDrawer?: boolean;
}) => {
  return (
    <>
      <SEOHead title={title} description={description} metaImage={metaImage} />

      <Script
        defer
        src="https://www.googletagmanager.com/gtag/js?id=G-B3ZF4G9794"
      />
      <Script id="google-analytics" strategy="afterInteractive" defer>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', "G-B3ZF4G9794");
        `}
      </Script>

      {/* Start of Hubspot Tracking Code for https://helloclever.co/ */}
      {process.env.NEXT_PUBLIC_ENVIRONMENT === "PRODUCTION" ? (
        <Script
          type="text/javascript"
          id="hs-script-loader"
          async
          src="//js.hs-scripts.com/7853100.js"
          strategy="lazyOnload"
        ></Script>
      ) : (
        <Script
          type="text/javascript"
          id="hs-script-loader"
          async
          strategy="lazyOnload"
          src="//js.hs-scripts.com/24196879.js"
        ></Script>
      )}
      {/* End of Hubspot Tracking Code for https://helloclever.co/ */}

      {/* Hotjar Tracking Code for https://helloclever.co/ */}
      {process.env.NEXT_PUBLIC_ENVIRONMENT === "PRODUCTION" && (
        <Script id="hot-jar" strategy="afterInteractive" defer>
          {`
          (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:3314364,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `}
        </Script>
      )}

      <main className="relative flex flex-1 flex-col">{children}</main>
    </>
  );
};

export default MainLayout;
