import { memo } from "react";

import { SectionWrap } from "Components/Layouts";
import { MAX_CONTENT_WIDTH } from "Constants/Common.Constant";
import { NormalImage } from "Components/Lib";

import gallery from "Assets/Images/Others/about_gallery.png";

const Section3 = () => {
  return (
    <SectionWrap
      containerProps={{ className: "my-8" }}
      contentContainerProps={{
        className: "min-w-[700px] flex-1 md:min-w-[1000px]",
      }}
      className="overflow-x-auto"
    >
      <NormalImage
        src={gallery.src}
        alt="about-gallery"
        height={500}
        width={MAX_CONTENT_WIDTH}
        sizes="70vw"
      />
    </SectionWrap>
  );
};

export default memo(Section3);
