import { AppDownLoadButton } from "Components/Own";
import { SectionWrap } from "Components/Layouts";

import TitleComponent from "./Title.Component";

import MerchantDiscover from "./MerchantDiscover.Component";

const Section1 = () => {
  return (
    <SectionWrap
      containerProps={{
        className: "bg-clever-primary",
      }}
      contentContainerProps={{
        className: "flex flex-col items-center pt-8 -mb-[280px] md:-mb-[380px]",
      }}
      className="flex items-center justify-center"
    >
      <TitleComponent />
      <AppDownLoadButton showSeperate theme="dark" className="mb-5" />
      <MerchantDiscover />
    </SectionWrap>
  );
};

export default Section1;
