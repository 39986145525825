import React from "react";

import { SectionWrap } from "Components/Layouts";
import { Button } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";
import { NextLink } from "Components/Lib/Next";
import { PATHS_NAME } from "Constants/PathName.Constant";

import TitleComponent from "./Title.Component";
import ImageComponent from "./Image.Component";

export default function Section1() {
  return (
    <SectionWrap
      className="flex items-center justify-center"
      containerProps={{ className: "bg-clever-secondary" }}
      contentContainerProps={{
        className:
          "flex flex-col items-center pt-8 -mb-[100px] sm:-mb-[150px] md:-mb-[220px] gap-10",
      }}
    >
      <TitleComponent />
      <NextLink href={PATHS_NAME.DASHBOARD} target="_blank">
        <Button
          className="mb-4 mt-4 rounded-full px-7"
          buttonSize="large"
          buttonType="primary_dark"
        >
          {localesText("common.get_started")}
        </Button>
      </NextLink>
      <ImageComponent />
    </SectionWrap>
  );
}
