import { memo } from "react";

import { Button, RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";
import { getCashback } from "Utils/Common.Util";
import { _toLower, _capitalize } from "Utils/Lodash.Util";
import { InstantDealProps } from "Types";
import { NextImage, NextLink } from "Components/Lib/Next";

import instantCashbackFull from "Assets/Images/Icons/instant_cashback_full.png";

const TitleComponent = ({ data }: { data: InstantDealProps }) => (
  <div className="flex-1 px-4">
    <div className="flex items-center md:max-w-[500px]">
      <div className="flex flex-1 items-center gap-4">
        <div className="bordered relative h-[60px] min-h-[60px] w-[60px] min-w-[60px] overflow-hidden rounded-full border-2 border-white-slate">
          <NextImage
            src={data?.avatar_url || ""}
            alt="avatar"
            fill
            style={{ objectFit: "cover" }}
            className="h-full w-full"
          />
        </div>
        <Typography.Content className="one-line-clamp font-semibold">
          {data?.name || ""}
        </Typography.Content>
      </div>

      <NextImage
        src={instantCashbackFull}
        alt="instant"
        width={155}
        height={28}
      />
    </div>

    <div className="flex flex-col pb-12 pt-10 text-center md:max-w-[500px] md:text-left">
      <div className="mb-6">
        <Typography.SectionTitle>
          {localesText("common.get")}{" "}
          {data?.up_to ? _toLower(localesText("common.up_to")) : ""}{" "}
          <RotateHighlightText
            content={getCashback({
              cashback: data?.cashback || 0,
              commission_type: data?.commission_type || "%",
              up_to: false,
            })}
          />
        </Typography.SectionTitle>
        <Typography.SectionTitle>
          {_capitalize(localesText("common.instant_cashback"))}
        </Typography.SectionTitle>
      </div>

      <Typography.Body className="text-clever-description">
        {data?.summary || ""}
      </Typography.Body>
    </div>

    <NextLink href={data?.tracking_url || ""} target="_blank">
      <Button className="h-[56px] rounded-full px-9" buttonType="primary">
        {localesText("common.shop_now")}
      </Button>
    </NextLink>
  </div>
);

export default memo(TitleComponent);
