import { SlateWhite } from "Components/Layouts";
import { Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

import GeometricFour from "Assets/Images/Others/geometric_four.png";

export default function TitleComponent() {
  return (
    <SlateWhite
      containerClassName="flex-1 relative overflow-hidden"
      contentContainerClassName="flex flex-col items-center sm:flex-row-reverse md:flex-col gap-3 md:gap-8 p-10"
      firstComponent={
        <Typography.Heading2 className="z-10 font-normal">
          {localesText("tyro.our_integrated")}
        </Typography.Heading2>
      }
      secondComponent={
        <img
          src={GeometricFour.src}
          className="absolute right-0 bottom-0"
          width={200}
          height={200}
        />
      }
    />
  );
}
