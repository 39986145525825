import { memo } from "react";

import { RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";
import { _toLower } from "Utils/Lodash.Util";

const TitleComponent = () => (
  <div className="flex flex-col items-center text-center">
    <Typography.SectionTitle>
      A <RotateHighlightText content={localesText("payto.solution")} />{" "}
      {localesText("payto.all_use_cases")}
    </Typography.SectionTitle>
  </div>
);

export default memo(TitleComponent);
