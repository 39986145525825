import { memo } from "react";

import { Typography } from "Components/Own";
import { NormalImage } from "Components/Lib";
import { localesText } from "Utils/LocalesText.Util";
import PaytoBanner from "Assets/Images/Icons/payto_full.png";

const TitleComponent = () => {
  return (
    <div className="flex flex-col items-center">
      <div className="mb-2 h-[55px] w-[110px] md:h-[84px] md:w-[168px]">
        <NormalImage
          src={PaytoBanner.src}
          alt="flower-mobile"
          width={168}
          height={84}
          sizes="80vw"
        />
      </div>
      <Typography.Giant className="max-w-[950px] text-center text-dark">
        {localesText("payto.instant_secure")}
      </Typography.Giant>
      <Typography.Giant className="max-w-[950px] text-center text-dark">
        {localesText("payto.account_to_account")}
      </Typography.Giant>

      <Typography.Content className="mt-2 max-w-[1000px] text-center text-dark sm:mt-4">
        {localesText("payto.transforming_the_way")}
      </Typography.Content>
    </div>
  );
};

export default memo(TitleComponent);
