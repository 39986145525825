import { memo } from "react";

import { HOW_WORKS, MAX_INNER_CONTENT_WIDTH } from "Constants/Common.Constant";
import { HOW_WORKS_TYPE } from "Types";
import { classnames } from "Utils/Classnames.Util";

const PaginatorComponent = ({ currentTab }: { currentTab: HOW_WORKS_TYPE }) => {
  const onClickTab = (id: string) => {
    const tab = document.getElementById(id);
    if (
      !!tab &&
      tab.scrollIntoView &&
      typeof tab.scrollIntoView === "function"
    ) {
      tab.scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "center",
      });
    }
  };

  return (
    <div
      className={classnames(
        "flex justify-center gap-5 p-5 lg:p-0",
        // desktop absolute
        "lg:absolute lg:left-16 lg:z-10 lg:bottom-16",
        // "md:absolute md:bottom-10 md:left-16 md:z-10 lg:bottom-16",
        "px-6 py-10 sm:p-10",
      )}
    >
      <div
        className="flex flex-1 items-center gap-3 overflow-x-auto font-medium"
        style={{ maxWidth: MAX_INNER_CONTENT_WIDTH }}
      >
        <div
          id={"tab-" + HOW_WORKS.STEP_ONE}
          onClick={() => onClickTab("step_one")}
          className={classnames(
            "flex h-3 cursor-pointer rounded-full transition-all",
            {
              "w-3 bg-[#CBD3E1]": currentTab !== HOW_WORKS.STEP_ONE,
              "w-10 bg-clever-primary": currentTab === HOW_WORKS.STEP_ONE,
            },
          )}
        />

        <div
          id={"tab-" + HOW_WORKS.STEP_TWO}
          onClick={() => onClickTab("step_two")}
          className={classnames(
            "flex h-3 cursor-pointer rounded-full transition-all",
            {
              "w-3 bg-[#CBD3E1]": currentTab !== HOW_WORKS.STEP_TWO,
              "w-10 bg-clever-primary": currentTab === HOW_WORKS.STEP_TWO,
            },
          )}
        />

        <div
          id={"tab-" + HOW_WORKS.STEP_THREE}
          onClick={() => onClickTab("step_three")}
          className={classnames(
            "flex h-3 cursor-pointer rounded-full transition-all",
            {
              "w-3 bg-[#CBD3E1]": currentTab !== HOW_WORKS.STEP_THREE,
              "w-10 bg-clever-primary": currentTab === HOW_WORKS.STEP_THREE,
            },
          )}
        />
      </div>
    </div>
  );
};

export default memo(PaginatorComponent);
