import { RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

const TitleComponent = () => (
  <div className="flex flex-1 flex-col pb-10 pt-16 text-center">
    <div className="mb-6 md:mb-8 md:min-h-[220px] lg:min-h-auto">
      <Typography.SectionTitle>
        {localesText("xero.simple")}
        <RotateHighlightText content={localesText("xero.fast")} />
        <br />
        {localesText("xero.invoicing")}
      </Typography.SectionTitle>
    </div>

    <Typography.Body className="max-w-[450px] text-clever-description sm:pr-4">
      {localesText("xero.simple_invoicing_text")}
    </Typography.Body>
  </div>
);

export default TitleComponent;
