import { HorizontalList } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";
import { useExpand } from "Hooks/useExpand";
import { MAX_INNER_CONTENT_WIDTH } from "Constants/Common.Constant";
import { useMobileScreen } from "Hooks/useMediaQuery";

import oneoff from "Assets/Images/Others/merchant_api_oneoff.png";
import recurring from "Assets/Images/Others/merchat_api_recurring.png";
import directDebit from "Assets/Images/Others/merchat_api_direct_debit.png";

import CashFlowItem from "./CashFlowItem";

const data = [
  {
    id: 1,
    title: localesText("merchant_api.one_off"),
    content: localesText("merchant_api.one_off_description"),
    image: oneoff.src,
  },
  {
    id: 2,
    title: localesText("merchant_api.recurring"),
    content: localesText("merchant_api.recurring_description"),
    image: recurring.src,
  },
  {
    id: 3,
    title: localesText("merchant_api.direct"),
    content: localesText("merchant_api.direct_description"),
    image: directDebit.src,
  },
];

const CashFlow = () => {
  const isMobile = useMobileScreen();
  const { expandingId, onToggle } = useExpand(isMobile ? 1 : 2);

  return (
    <HorizontalList
      containerClassName="my-8"
      contentContainerClassName="transition-all duration-500"
      data={data}
      ItemComponent={CashFlowItem}
      itemSize={325}
      disableAllScale
      manualListWidth={expandingId ? MAX_INNER_CONTENT_WIDTH : undefined}
      itemProps={{ onToggle, expandingId }}
    />
  );
};

export default CashFlow;
