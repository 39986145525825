import { WrapList } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

import TakePayto from "./TakePayto.Component";

const data = [
  {
    id: 1,
    image: "Assets/Svg/boost.svg",
    title: localesText("payto.boost_customer"),
    content: localesText("payto.boost_customer_description"),
  },
  // {
  //   id: 2,
  //   image: "Assets/Svg/integration.svg",
  //   title: localesText("payto.enjoy"),
  //   content: localesText("payto.enjoy_description"),
  // },
  {
    id: 2,
    image: "Assets/Svg/migration.svg",
    title: localesText("payto.easy_migration"),
    content: localesText("payto.easy_migration_description"),
  },
];

const TakePaytoList = () => {
  return (
    <WrapList
      data={data}
      ItemComponent={TakePayto}
      itemSize={580}
      contentContainerClassName="gap-y-7 gap-[40px]"
      fillNumber={0}
      shoulScale={false}
    />
  );
};

export default TakePaytoList;
