import { useEffect, useRef, useState } from "react";

import { useIsInViewport } from "Hooks/useIsInViewPort";
import { HorizontalList } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";
import { useExpand } from "Hooks/useExpand";
import { MAX_INNER_CONTENT_WIDTH } from "Constants/Common.Constant";
import { logAnalyticEvent } from "Utils/Analytics.Util";
import { TRACKING_EVENTS } from "Constants/Event.Constant";

import CashFlowItem from "./CashFlowItem";

import cashFlowFirst from "Assets/Images/Others/cash_flow_first.png";
import cashFlowSecond from "Assets/Images/Others/cash_flow_second.png";
import cashFlowThird from "Assets/Images/Others/cash_flow_third.png";

const data = [
  {
    id: 1,
    title: localesText("buy_to_earn.reduce_chargebacks"),
    content: localesText("buy_to_earn.reduce_chargebacks_description"),
    image: cashFlowFirst.src,
  },
  {
    id: 2,
    title: localesText("buy_to_earn.immediate_reconciliation"),
    content: localesText("buy_to_earn.immediate_reconciliation_description"),
    image: cashFlowSecond.src,
  },
  {
    id: 3,
    title: localesText("buy_to_earn.real_time_settlement"),
    content: localesText("buy_to_earn.real_time_settlement_description"),
    image: cashFlowThird.src,
  },
];

const CashFlow = () => {
  const { expandingId, onToggle } = useExpand(2);

  const scrollRef = useRef<any>(null);
  const isInViewport = useIsInViewport(scrollRef);

  const [firstScroll, setFirstScroll] = useState(true);

  useEffect(() => {
    if (isInViewport && firstScroll) {
      setFirstScroll(false);
      logAnalyticEvent({ key: TRACKING_EVENTS.consumerBenefitViewed });
    }
  }, [firstScroll, isInViewport]);

  return (
    <div ref={scrollRef}>
      <HorizontalList
        containerClassName="my-8"
        contentContainerClassName="transition-all duration-500"
        data={data}
        ItemComponent={CashFlowItem}
        itemSize={325}
        disableAllScale
        manualListWidth={expandingId ? MAX_INNER_CONTENT_WIDTH : undefined}
        itemProps={{ onToggle, expandingId }}
      />
    </div>
  );
};

export default CashFlow;
