import { memo } from "react";

import { RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";
import { _toLower } from "Utils/Lodash.Util";

const TitleComponent = () => (
  <div className="mb-10 flex flex-col items-center px-5 text-center md:mb-14">
    <Typography.SectionTitle className="mb-2">
      {localesText("buy_to_earn.do_you_have")}
    </Typography.SectionTitle>
    <Typography.SectionTitle className="mb-8">
      <RotateHighlightText content={localesText("common.development")} />{" "}
      {localesText("common.team")}?
    </Typography.SectionTitle>
    <Typography.Body className="text-clever-description md:w-[70%]">
      {localesText("buy_to_earn.can_do_much_more")}
    </Typography.Body>
  </div>
);

export default memo(TitleComponent);
