import { useEffect, useState } from "react";

import { NextLink } from "Components/Lib/Next";
import { Button, RotateHighlightText, Typography } from "Components/Own";
import { MERCHANT_SIGN_UP } from "Constants/URL.Constant";
import { getStoresCount } from "Services";
import { localesText } from "Utils/LocalesText.Util";
import { formatNumber } from "Utils/Number.Util";

const TitleComponent = () => {
  const [storeCount, setStoreCount] = useState<number>(0);

  useEffect(() => {
    const fetchStoreCount = async () => {
      const response = await getStoresCount();
      setStoreCount(response.storeCount);
    };
    fetchStoreCount();
  }, []);

  return (
    <div className="flex flex-1 flex-col justify-center">
      <div className="mb-5 text-center md:text-left">
        <Typography.SectionTitle>
          {localesText("about.trusted_by")}
        </Typography.SectionTitle>
        <Typography.SectionTitle>
          <RotateHighlightText content={`${formatNumber(storeCount)}+`} />{" "}
          {localesText("about.merchants")}
        </Typography.SectionTitle>
      </div>

      <Typography.Body className="mb-6 text-center text-clever-description md:w-[80%] md:px-0 md:text-left">
        {localesText("about.by_supporting_merchants")}
      </Typography.Body>

      <div className="self-center md:self-start">
        <NextLink href={MERCHANT_SIGN_UP} target="_blank">
          <Button className="h-[56px] rounded-full px-9" buttonType="primary">
            {localesText("about.become_merchant")}
          </Button>
        </NextLink>
      </div>
    </div>
  );
};

export default TitleComponent;
