import { SectionWrap } from "Components/Layouts";

import InstantList from "./InstantList";
import TitleComponent from "./Title.Component";

const Section5 = () => {
  return (
    <SectionWrap
      containerProps={{
        className:
          "flex justify-center bg-clever-white-slate py-[80px] md:py-[120px]",
      }}
      className="flex justify-center"
      contentContainerProps={{
        className:
          "flex justify-center items-center gap-10 w-full flex-col gap-12",
      }}
    >
      <TitleComponent />
      <InstantList />
    </SectionWrap>
  );
};

export default Section5;
