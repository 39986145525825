import { SectionWrap } from "Components/Layouts";

import TitleComponent from "./Title.Component";
import TakePaytoList from "./TakePaytoList";

const TakePayto = () => {
  return (
    <SectionWrap
      className="flex items-center justify-center"
      contentContainerProps={{
        className: "flex w-full flex-col items-center gap-14",
      }}
    >
      <TitleComponent />
      <TakePaytoList />
    </SectionWrap>
  );
};

export default TakePayto;
