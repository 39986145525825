import { memo } from "react";

import { NextImage } from "Components/Lib/Next";
import { LAUNTEL_VIDEO_URL } from "Constants/URL.Constant";

import launtelCeo from "Assets/Images/Others/merchant_api_launtel_ceo.png";

const ImageComponent = () => {
  return (
    <div className="relative flex flex-1 flex-col items-center justify-center">
      <a href={LAUNTEL_VIDEO_URL} target="_blank" rel="noreferrer">
        <div className="hidden md:absolute md:top-[35%] md:left-[-10px] md:block">
          <img src="/Assets/Svg/play.svg" width={100} height={100} alt="play" />
        </div>
        <div className="mb-4 md:hidden">
          <img src="/Assets/Svg/play.svg" width={60} height={60} alt="play" />
        </div>
      </a>

      <NextImage src={launtelCeo} alt="launtel-ceo" width={625} height={900} />
    </div>
  );
};

export default memo(ImageComponent);
