import { memo, useEffect, useRef } from "react";

import { NormalImage } from "Components/Lib";
import { useIsInViewport } from "Hooks/useIsInViewPort";
import { HOW_WORKS_TYPE } from "Types";
import { localesText } from "Utils/LocalesText.Util";

import ReferSlider from "Assets/Images/Others/xero_slider_one.png";

import Title from "../Title.Component";

const BuyToEarnTab = ({
  tabName,
  isActive,
  onTabInViewPort,
  paginatorScroll,
}: {
  tabName: HOW_WORKS_TYPE;
  isActive: boolean;
  onTabInViewPort: (name: HOW_WORKS_TYPE) => void;
  paginatorScroll: (id: string) => void;
}) => {
  const ref = useRef<any>(null);

  const isInViewport = useIsInViewport(ref);

  useEffect(() => {
    if (isInViewport && !isActive) {
      onTabInViewPort(tabName);
      paginatorScroll("tab-" + tabName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive, isInViewport]);

  return (
    <>
      <Title
        title={localesText("xero.step_one")}
        description={localesText("xero.it_work_text_one")}
      />
      <div ref={ref} className="h-3 w-3 self-center" />
      <div className="ml-10 flex flex-1 items-center md:ml-0">
        <NormalImage src={ReferSlider.src} width={730} height={710} />
      </div>
    </>
  );
};

export default memo(BuyToEarnTab);
