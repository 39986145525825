import { Typography } from "Components/Own";

const Customer = ({
  item,
  itemWidth,
}: {
  item: {
    id: number;
    image: any;
    avatar: any;
    title: string;
    description: string;
    name: string;
    address: string;
  };
  itemWidth: number;
}) => {
  return (
    <div
      style={{ width: itemWidth }}
      className="flex flex-col rounded-[40px] bg-white-slate p-5 md:p-10 flex-1 relative gap-8 overflow-hidden"
    >
      <Typography.Heading3 className="flex-1 z-10">
        {item.description}
      </Typography.Heading3>
      <div className="flex gap-3  h-[60px] flex-shrink-0 z-10">
        <img src={item.avatar} width={60} height={60} alt="customer-avatar" />
        <div>
          <Typography.Body className="mb-1 mt-auto z-10">
            {item.name}
          </Typography.Body>
          <Typography.Description className="mb-1 z-10">
            {item.address}
          </Typography.Description>
        </div>
      </div>
      <img
        src={item.image}
        width={200}
        height={60}
        alt="customer-avatar"
        className="absolute right-0 bottom-0"
      />
    </div>
  );
};

export default Customer;
