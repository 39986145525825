import { memo } from "react";

import { LottieImage } from "Components/Own";

import CashbackReceived from "Assets/Lotties/cashback_received.json";

const ImageComponent = () => (
  <div className="flex flex-1 items-center justify-center ">
    <div className="relative w-full max-w-[530px]">
      <LottieImage options={{ animationData: CashbackReceived }} />
    </div>
  </div>
);

export default memo(ImageComponent);
