import { memo } from "react";

import {
  Typography,
  RotateHighlightText,
  AppDownLoadButton,
} from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

const TitleComponent = ({ storeCount }: { storeCount: number }) => {
  return (
    <>
      <div className="mb-10 flex flex-col text-center text-white sm:w-[70%] sm:text-left">
        <Typography.Giant>
          {localesText("shops.shop_at")}{" "}
          <RotateHighlightText content={`${storeCount}+`} />{" "}
          {localesText("shops.stores")} {localesText("shops.stores_from")}
        </Typography.Giant>
      </div>
      <div className="flex justify-center sm:justify-start">
        <AppDownLoadButton
          theme="dark"
          showSeperate
          className="justify-center"
        />
      </div>
    </>
  );
};

export default memo(TitleComponent);
