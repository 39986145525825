import { RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

export default function TitleComponent() {
  return (
    <div>
      <Typography.SectionTitle className="text-center">
        {localesText("tyro.instant_cashback")}
        <br></br>
        <RotateHighlightText
          content={localesText("tyro.made_simple")}
          className="mr-3 inline-block"
        />
        {localesText("tyro.for_businesses")}
      </Typography.SectionTitle>
    </div>
  );
}
