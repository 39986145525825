import { SectionWrap } from "Components/Layouts";

import TitleComponent from "./Title.Component";
import Benefits from "./Benefits";

const Section6 = () => {
  return (
    <SectionWrap
      containerProps={{ className: "py-12 pb-0 md:pb-12" }}
      className="flex items-center justify-center"
      contentContainerProps={{
        className: "flex w-full flex-col",
      }}
    >
      <TitleComponent />
      <Benefits />
    </SectionWrap>
  );
};

export default Section6;
