import { memo } from "react";

import { Button } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";
import { NextLink } from "Components/Lib/Next";
import { DEVELOPER_API_REF } from "Constants/URL.Constant";

const DocumentAPI = () => {
  return (
    <NextLink href={DEVELOPER_API_REF} target="_blank" className="my-14">
      <Button
        buttonSize="large"
        className="rounded-full px-12"
        buttonType="primary"
      >
        {localesText("buy_to_earn.document_api")}
      </Button>
    </NextLink>
  );
};

export default memo(DocumentAPI);
