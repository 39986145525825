import { memo } from "react";

import { RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";
import { _toLower } from "Utils/Lodash.Util";

const TitleComponent = () => (
  <div className="mb-8 flex flex-col items-center text-center">
    <Typography.SectionTitle className="mb-2">
      {localesText("merchant_api.do_much_more")}{" "}
    </Typography.SectionTitle>
    <Typography.SectionTitle>
      {localesText("common.hello_clever")}{" "}
      <RotateHighlightText content={localesText("merchant_api.payment_api")} />
    </Typography.SectionTitle>
  </div>
);

export default memo(TitleComponent);
