import { WrapList } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";
import { useMobileScreen } from "Hooks/useMediaQuery";

import Solution from "./Solution.Component";

const data = [
  {
    id: 1,
    image: "Assets/Svg/hospitality.svg",
    title: localesText("tyro.hospitality"),
  },
  {
    id: 2,
    image: "Assets/Svg/retails.svg",
    title: localesText("tyro.retail"),
  },
  {
    id: 3,
    image: "Assets/Svg/bag.svg",
    title: localesText("tyro.services"),
  },
  {
    id: 4,
    image: "Assets/Svg/key.svg",
    title: localesText("tyro.accommodation"),
  },
];

const SolutionList = () => {
  const isMobile = useMobileScreen();

  return (
    <WrapList
      data={data}
      ItemComponent={Solution}
      itemSize={isMobile ? 340 : 310}
      contentContainerClassName="gap-y-4"
      shoulScale={false}
      fillNumber={0}
    />
  );
};

export default SolutionList;
