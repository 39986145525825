import { useEffect, useState } from "react";

import { NextLink } from "Components/Lib/Next";
import { Button, ExpandButton, LottieImage, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";
import { classnames } from "Utils/Classnames.Util";
import { logAnalyticEvent } from "Utils/Analytics.Util";
import { TRACKING_EVENTS } from "Constants/Event.Constant";
import { PATHS_NAME } from "Constants/PathName.Constant";

const CollapseComponent = ({
  index,
  id,
  title,
  content,
  isExpanding,
  onToggle,
  lottieData,
  href,
}: {
  index: number;
  id: number;
  href: string;
  title: string;
  content: string;
  isExpanding: boolean;
  lottieData: any;
  onToggle: (id: number) => void;
}) => {
  const [isPlay, setIsPlay] = useState(false);

  useEffect(() => {
    if (isExpanding) {
      logAnalyticEvent({ key: TRACKING_EVENTS.industryViewed });
      setIsPlay(true);
    } else {
      setIsPlay(false);
    }
  }, [isExpanding]);

  return (
    <div
      id={`collapse-id-${href}`}
      tabIndex={index}
      className={classnames(
        "collapse border-b-[1px] border-b-base-300",
        `collapse-id-${href}`,
        {
          "collapse-close": !isExpanding,
        }
      )}
    >
      <input
        type="checkbox"
        className="cursor-pointer"
        checked={isExpanding}
        onChange={() => onToggle(id)}
      />

      <div className="collapse-title px-0 py-6">
        <div className="flex items-center justify-between gap-4 overflow-hidden">
          <Typography.Heading2
            className="flex-1 text-left"
            onClick={() => onToggle(id)}
          >
            {title}
          </Typography.Heading2>
          <ExpandButton
            inputProps={{
              checked: isExpanding,
              onChange: (event) => {
                event.preventDefault();
                event.stopPropagation();
                onToggle(id);
              },
            }}
            containerProps={{
              className: "bg-gray-100",
            }}
          />
        </div>
      </div>
      <div className="collapse-content pl-0">
        <div tabIndex={index} className="py-5">
          <div className="mb-6 flex items-center justify-center lg:hidden">
            <div className="max-h-[300px] max-w-[300px]">
              <LottieImage
                options={{ animationData: lottieData }}
                useObserver={false}
                isStopped={!isPlay}
              />
            </div>
          </div>

          <Typography.Body className="mb-5 text-clever-description">
            {content}
          </Typography.Body>

          <NextLink
            href={PATHS_NAME.GET_IN_TOUCH}
            onClick={() => {
              logAnalyticEvent({ key: TRACKING_EVENTS.usingTypeformData });
            }}
          >
            <Button className="rounded-3xl bg-black px-5">
              <Typography.Content>
                {localesText("common.say_hello")}
              </Typography.Content>
            </Button>
          </NextLink>
        </div>
      </div>
    </div>
  );
};

export default CollapseComponent;
