import { BlogSection, VentureSection } from "Components/Own";

import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";

const AboutUs = ({ blogs }: { blogs: any[] }) => {
  return (
    <div>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <BlogSection blogs={blogs} />
      <VentureSection />
    </div>
  );
};

export default AboutUs;
