//
import { useState } from "react";

import { SectionWrap } from "Components/Layouts";
import { HOW_WORKS } from "Constants/Common.Constant";
import { TRACKING_EVENTS } from "Constants/Event.Constant";
import { HOW_WORKS_TYPE } from "Types";
import { logAnalyticEvent } from "Utils/Analytics.Util";

import PaginatorComponent from "./Paginator.Component";
import StepOne from "./StepOne";
import StepThree from "./StepThree";
import StepTwo from "./StepTwo";

let timeOutId: any;
let paginatortimeOutId: any;

const Section4 = () => {
  const [active, setActive] = useState<HOW_WORKS_TYPE>(HOW_WORKS.STEP_ONE);

  const onTabInViewPort = (tabName: HOW_WORKS_TYPE) => {
    if (tabName !== active) {
      clearTimeout(timeOutId);
      timeOutId = setTimeout(() => {
        logAnalyticEvent({ key: TRACKING_EVENTS.serviceViewed });
        setActive(tabName);
      }, 300);
    }
  };

  const paginatorScroll = (paginatorId: string) => {
    const paginatorEle = document.getElementById(paginatorId);
    if (
      !!paginatorEle &&
      paginatorEle.scrollIntoView &&
      typeof paginatorEle.scrollIntoView === "function"
    ) {
      clearTimeout(paginatortimeOutId);
      paginatortimeOutId = setTimeout(() => {
        paginatorEle.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "nearest",
        });
      }, 500);
    }
  };

  return (
    <SectionWrap
      noSidePadding
      contentContainerProps={{
        className:
          "flex flex-col justify-center overflow-hidden md:mx-[2%] md:rounded-[40px] bg-white-slate relative",
      }}
      className="flex justify-center"
    >
      <div className="carousel w-full">
        <SlideWrapper id={HOW_WORKS.STEP_ONE}>
          <StepOne
            tabName={HOW_WORKS.STEP_ONE}
            isActive={active === HOW_WORKS.STEP_ONE}
            onTabInViewPort={onTabInViewPort}
            paginatorScroll={paginatorScroll}
          />
        </SlideWrapper>
        <SlideWrapper id={HOW_WORKS.STEP_TWO}>
          <StepTwo
            tabName={HOW_WORKS.STEP_TWO}
            isActive={active === HOW_WORKS.STEP_TWO}
            onTabInViewPort={onTabInViewPort}
            paginatorScroll={paginatorScroll}
          />
        </SlideWrapper>
        <SlideWrapper id={HOW_WORKS.STEP_THREE}>
          <StepThree
            tabName={HOW_WORKS.STEP_THREE}
            isActive={active === HOW_WORKS.STEP_THREE}
            onTabInViewPort={onTabInViewPort}
            paginatorScroll={paginatorScroll}
          />
        </SlideWrapper>
      </div>
      <PaginatorComponent currentTab={active} />
    </SectionWrap>
  );
};

const SlideWrapper = ({ children, id }: { children: any; id: string }) => {
  return (
    <div id={id} className="carousel-item w-full pt-10">
      <div className="flex flex-1 flex-col-reverse pr-[1px] md:flex-row">
        {children}
      </div>
    </div>
  );
};

export default Section4;
