import { HorizontalList } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

import Customer from "./Customer.Component";

const CustomerList = () => {
  const data = [
    {
      id: 1,
      title: localesText("in_store.link_your_cards"),
      description: localesText("in_store.the_instant_cashback"),
      name: localesText("in_store.customer_name_one"),
      address: localesText("in_store.customer_address_one"),
      avatar: "Assets/Images/Others/customer_avatar_one.png",
      image: "Assets/Images/Others/geometric_one.png",
    },
    {
      id: 2,
      title: localesText("in_store.explore_stores"),
      description: localesText("in_store.recently_discovered"),
      name: localesText("in_store.customer_name_two"),
      address: localesText("in_store.customer_address_two"),
      avatar: "Assets/Images/Others/customer_avatar_two.png",
      image: "Assets/Images/Others/geometric_two.png",
    },
    {
      id: 3,
      title: localesText("in_store.pay_and_earn"),
      description: localesText("in_store.the_user_interface"),
      name: localesText("in_store.customer_name_three"),
      address: localesText("in_store.customer_address_three"),
      avatar: "Assets/Images/Others/customer_avatar_three.png",
      image: "Assets/Images/Others/geometric_three.png",
    },
  ];

  return (
    <HorizontalList
      containerClassName="my-8 px-[4%] sm:px-[3%] md:px-[2%]"
      data={data}
      ItemComponent={Customer}
      itemSize={440}
    />
  );
};

export default CustomerList;
