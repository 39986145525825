import { SectionWrap } from "Components/Layouts";
import { OfferItem, WrapList } from "Components/Own";
import { InstantDealProps } from "Types";
import { useMobileScreen } from "Hooks/useMediaQuery";

const Section2 = ({ instantDeals }: { instantDeals: InstantDealProps[] }) => {
  const isMobile = useMobileScreen();

  return (
    <SectionWrap
      containerProps={{
        className: "pt-16 pb-8",
      }}
      contentContainerProps={{
        style: { maxWidth: 1200 },
      }}
      className="flex justify-center"
    >
      <WrapList
        data={instantDeals}
        ItemComponent={OfferItem}
        itemSize={!isMobile ? 224 : 300}
        contentContainerClassName="gap-y-10"
        shoulScale={!isMobile}
        fillNumber={4}
      />
    </SectionWrap>
  );
};

export default Section2;
