import { memo } from "react";

import { NextLink } from "Components/Lib/Next";
import { Button, Typography } from "Components/Own";
import { PATHS_NAME } from "Constants/PathName.Constant";
import { generatePath } from "Utils/Common.Util";
import { localesText } from "Utils/LocalesText.Util";
import { formatMoment } from "Utils/Moment.Util";

const TitleComponent = ({
  content,
  date,
  link,
}: {
  content: string;
  date: string;
  link: string;
}) => {
  return (
    <div className="flex-1">
      <Typography.Description className="mb-2 text-white opacity-70 md:mb-4">
        {formatMoment({ date, format: "DD MMMM YYYY" })}
      </Typography.Description>
      <Typography.Heading1 className="mb-5 text-white md:mb-10">
        <NextLink
          href={generatePath(PATHS_NAME.BLOG_DETAIL, {
            blog_slug: link,
          })}
        >
          {content}
        </NextLink>
      </Typography.Heading1>

      <NextLink
        href={generatePath(PATHS_NAME.BLOG_DETAIL, {
          blog_slug: link,
        })}
      >
        <Button buttonSize="large" className="rounded-full bg-black" wide>
          {localesText("common.read_more")}
        </Button>
      </NextLink>
    </div>
  );
};

export default memo(TitleComponent);
