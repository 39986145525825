import { memo } from "react";

import { LottieImage, Typography } from "Components/Own";

const Benefit = ({
  item,
  itemWidth,
}: {
  item: {
    id: number;
    lottie: any;
    title: string;
    content: string;
    background: string;
  };
  itemWidth: number;
}) => {
  return (
    <div
      className="flex flex-col overflow-hidden"
      style={{ width: itemWidth }}
      suppressHydrationWarning={true}
    >
      <div className="mb-4 flex aspect-square w-[60px] md:mb-8">
        <LottieImage
          options={{
            animationData: "",
            url: item.lottie,
            loop: true,
            autoplay: true,
          }}
          useObserver={false}
          loopCount={3000}
        />
      </div>
      <Typography.Heading2 className="min-h-12 w-[90%] text-xl font-normal sm:min-h-12 sm:mb-5 sm:text-2xl md:h-[70px]">
        {item.title}
      </Typography.Heading2>
      <Typography.Description className="text-clever-description">
        {item.content}
      </Typography.Description>
    </div>
  );
};

export default memo(Benefit);
