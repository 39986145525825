import { SectionWrap } from "Components/Layouts";

import CustomerList from "./CustomerList";
import TitleComponent from "./Title.Component";

const Section9 = () => {
  return (
    <div className="mb-[80px] md:mb-[120px]">
      <SectionWrap
        containerProps={{
          className: "",
        }}
        className="flex items-center justify-center"
        contentContainerProps={{
          className:
            "w-full flex flex-col gap-10 md:gap-20 md:gap-12 justify-center items-center",
        }}
      >
        <TitleComponent />
      </SectionWrap>
      <CustomerList />
    </div>
  );
};

export default Section9;
