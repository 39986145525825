import { memo } from "react";

import { classnames } from "Utils/Classnames.Util";
import { NormalImage } from "Components/Lib";

const Image = ({
  isExpanding,
  image,
  itemWidth,
}: {
  image: any;
  isExpanding: boolean;
  itemWidth: number;
}) => {
  return (
    <div
      className="relative aspect-square h-full overflow-hidden rounded-[40px] transition-all duration-500"
      style={{ maxWidth: itemWidth }}
    >
      <NormalImage
        src={image}
        alt="cash-flow"
        sizes="325px"
        className="mx-auto h-full w-full object-cover object-center"
      />
      <div
        className={classnames(
          "absolute inset-0 z-10 bg-gradient-to-b from-[#FFFFFF99] via-[#FFFFFF79] transition-all duration-500",
          {
            "opacity-0": isExpanding,
          }
        )}
      />
    </div>
  );
};

export default memo(Image);
