import { useEffect } from "react";

import { logAnalyticEvent } from "Utils/Analytics.Util";
import { TRACKING_EVENTS } from "Constants/Event.Constant";
import { localesText } from "Utils/LocalesText.Util";
import { useExpand } from "Hooks/useExpand";

import CollapseComponent from "./Collapse.Component";

const data = [
  {
    id: 1,
    title: localesText("buy_to_earn.how_long"),
    content:
      "Integration can be completed in under 24hrs. However, if you require a customised offering it can take longer. Reach out to us at",
    highlight_content: "support@helloclever.co",
  },
  {
    id: 2,
    title: localesText("buy_to_earn.payment_options"),
    content: "We offer all major credit cards, PayID and PayTo.",
    highlight_content: "",
  },
  {
    id: 3,
    title: localesText("buy_to_earn.which_countries"),
    content:
      "We support international and domestic card payments. Currently we are only available in Australia for PayID and PayTo. We are working on expanding into the SEA region in the near future.",
    highlight_content: "",
  },
];

const Collapses = () => {
  const { expandingId, onToggle } = useExpand();

  useEffect(() => {
    if (expandingId) {
      logAnalyticEvent({ key: TRACKING_EVENTS.faqViewed });
    }
  }, [expandingId]);

  return (
    <div className="flex-1">
      {data.map((item, index) => {
        return (
          <CollapseComponent
            index={index}
            key={item.id.toString()}
            title={item.title}
            content={item.content}
            highlight_content={item.highlight_content}
            id={item.id}
            onToggle={onToggle}
            isExpanding={expandingId === item.id}
          />
        );
      })}
    </div>
  );
};

export default Collapses;
