import { WrapList } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";
import { useMobileScreen } from "Hooks/useMediaQuery";

import Benefit from "./Benefit.Component";

const data = [
  {
    id: 1,
    image: "Assets/Svg/fraud.svg",
    title: localesText("payto.payto_fraud"),
    content: localesText("payto.payto_fraud_description"),
  },
  {
    id: 3,
    image: "Assets/Svg/faq.svg",
    title: localesText("payto.payto_faq"),
    content: localesText("payto.payto_faq_description"),
  },
  {
    id: 4,
    image: "Assets/Svg/track.svg",
    alt: "benefit",
    title: localesText("payto.payto_track"),
    content: localesText("payto.payto_track_description"),
  },
  {
    id: 5,
    image: "Assets/Svg/reduce.svg",
    alt: "benefit",
    title: localesText("payto.payto_reduce"),
    content: localesText("payto.payto_reduce_description"),
  },
];

const Benefits = () => {
  const isMobile = useMobileScreen();

  return (
    <WrapList
      data={data}
      ItemComponent={Benefit}
      itemSize={isMobile ? 340 : 310}
      shoulScale={false}
      contentContainerClassName="gap-y-10"
    />
  );
};

export default Benefits;
