import { SectionWrap } from "Components/Layouts";

import ImageComponent from "./Image.Component";
import TitleComponent from "./Title.Component";

export default function Section1() {
  return (
    <SectionWrap
      className="flex items-center justify-center"
      containerProps={{ className: "bg-clever-primary" }}
      contentContainerProps={{
        className:
          "flex flex-col items-center pt-8 -mb-[100px] sm:-mb-[150px] md:-mb-[220px] gap-10",
      }}
    >
      <TitleComponent />

      <ImageComponent />
    </SectionWrap>
  );
}
