import { memo } from "react";

import { RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

const TitleComponent = () => (
  <div className="flex flex-1 flex-col text-center sm:text-left">
    <div className="mb-6">
      <Typography.SectionTitle>
        <RotateHighlightText content={localesText("common.save")} />{" "}
        {localesText("common.as")}
      </Typography.SectionTitle>
      <Typography.SectionTitle>
        {localesText("home.you_spend")}
      </Typography.SectionTitle>
    </div>

    <Typography.Body className="text-clever-description sm:pr-4">
      {localesText("home.withdraw_or_save_for_later")}
    </Typography.Body>
  </div>
);

export default memo(TitleComponent);
