import React from "react";

import { RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

export default function TitleComponent() {
  return (
    <div className="flex flex-col items-center">
      <Typography.Giant className="text-center text-dark">
        {localesText("xero.receive_real_time")}
        <RotateHighlightText content={localesText("xero.xero")} />

        {localesText("xero.payments_in")}
      </Typography.Giant>
    </div>
  );
}
