import { localesText } from "./LocalesText.Util";
import { _isObject, _get, _isArray, _isString } from "./Lodash.Util";

export const DEFAULT_ERROR_MESSAGE = localesText(
  "errors.oops_something_went_wrong"
);
export const normalizeError = (errors: any) => {
  if (!errors) return DEFAULT_ERROR_MESSAGE;

  if (_isObject(errors)) {
    return (
      _get(errors, "response.data.errors.message", null) ||
      _get(errors, "errors.message", null) ||
      _get(errors, "message", null) ||
      DEFAULT_ERROR_MESSAGE
    );
  }

  if (_isArray(errors)) {
    return errors[0]
      ? errors[0].message
        ? errors[0].message
        : errors[0]
      : DEFAULT_ERROR_MESSAGE;
  }

  if (_isString(errors)) return errors;

  if (errors.message) {
    return errors.message;
  }

  return DEFAULT_ERROR_MESSAGE;
};
