import { memo } from "react";

import { RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

const TitleComponent = () => {
  return (
    <div className="flex flex-1 flex-col justify-center">
      <div className="mb-6 text-left">
        <Typography.SectionTitle>
          <RotateHighlightText
            content={localesText("common.save")}
            className="mr-3 inline-block"
          />
          {localesText("in_store.spend_in_store")}
        </Typography.SectionTitle>
      </div>

      <div className="text-clever-description sm:pr-4 md:pr-11">
        <Typography.Body className="mb-6">
          {localesText("in_store.link_bank_card")}
        </Typography.Body>
        <Typography.Body>
          {localesText("in_store.forget_waiting")}
        </Typography.Body>
      </div>
    </div>
  );
};

export default memo(TitleComponent);
