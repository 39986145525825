/* eslint-disable react-hooks/exhaustive-deps */
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";

import { SectionWrap } from "Components/Layouts";
import { WrapList } from "Components/Own";
import { BLOGS_CONTANT, BLOG_CATEGORY } from "Constants/Common.Constant";
import { PATHS_NAME } from "Constants/PathName.Constant";
import { classnames } from "Utils/Classnames.Util";

import BlogItem from "./BlogItem";

const Section2 = ({ blogs }: { blogs: any[] }) => {
  const route = useRouter();
  const currentPath = route.asPath.replace(PATHS_NAME.BLOGS + "#", "");

  const [dataState, setDataState] = useState({
    blogs: blogs || [],
    currentTab: BLOG_CATEGORY.ALL_CATEGORIES,
  });

  useEffect(() => {
    if (
      currentPath !== PATHS_NAME.BLOGS &&
      currentPath !== BLOG_CATEGORY.ALL_CATEGORIES.slug
    ) {
      const activeTab = generateTab(currentPath);
      const filteredBlogs = blogs.filter((blog: any) => {
        const newBlog = blog?.[BLOGS_CONTANT.EMBEDDED]?.[
          BLOGS_CONTANT.TERM
        ]?.[0].find((item: any) => item.slug === currentPath);
        return newBlog;
      });
      setDataState((prev) => ({
        ...prev,
        blogs: filteredBlogs,
        currentTab: activeTab || BLOG_CATEGORY.ALL_CATEGORIES,
      }));
    } else {
      setDataState((prev) => ({
        ...prev,
        blogs,
        currentTab: BLOG_CATEGORY.ALL_CATEGORIES,
      }));
    }
  }, [blogs, currentPath]);

  const generateTab = (href: string) => {
    return Object.values(BLOG_CATEGORY).find((item) => item.slug === href);
  };

  const handleClick = (item: any) => {
    if (item.slug === BLOG_CATEGORY.CASE_STUDY.slug) {
      return route.push(PATHS_NAME.CASE_STUDIES);
    }
    route.push(PATHS_NAME.BLOGS + `#${item.slug}`);
  };

  return (
    <SectionWrap
      containerProps={{
        className: "pt-24 pb-8",
      }}
    >
      <div className="mx-auto  md:max-w-[710px] lg:max-w-[1075px]">
        <div className="tab-list flex overflow-auto scrollbar-hide mb-8 md:mb-14">
          {Object.values(BLOG_CATEGORY).map((item, index) => {
            const activeTab = item.slug === dataState.currentTab.slug;

            return (
              <div
                key={`${item.slug}-${index}`}
                onClick={() => handleClick(item)}
                className={classnames(
                  "tab-item cursor-pointer  px-3 sm:px-5 py-2 sm:py-3 font-normal border-b",
                  {
                    "border-clever-primary border-b-3 font-semibold opacity-100":
                      activeTab,
                    "opacity-80 hover:opacity-100": !activeTab,
                    "min-w-[142px]":
                      item.slug === BLOG_CATEGORY.ALL_CATEGORIES.slug,
                    "min-w-[120px]":
                      item.slug === BLOG_CATEGORY.CASE_STUDY.slug,
                  },
                )}
              >
                {item.name}
              </div>
            );
          })}
        </div>
      </div>

      <WrapList
        data={dataState.blogs}
        ItemComponent={BlogItem}
        itemSize={345}
      />
    </SectionWrap>
  );
};

export default Section2;
