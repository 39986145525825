import { memo, useEffect, useMemo, useRef } from "react";

import { INDUSTRIES } from ".";
import { LottieImage } from "Components/Own";

const ImageComponent = ({ id }: { id: number }) => {
  const ref = useRef<any>(null);

  const selectedItem = useMemo(() => {
    if (id) {
      return INDUSTRIES.find((item: any) => item.id === id);
    }

    return INDUSTRIES[0];
  }, [id]);

  useEffect(() => {
    ref.current?.play();
  }, [id]);

  return (
    <div className="hidden h-full flex-1 items-center justify-center lg:flex">
      <div className="max-h-[490px] max-w-[490px]">
        <LottieImage
          lottieRef={ref}
          options={{
            animationData: selectedItem?.lottieData || INDUSTRIES[0].lottieData,
          }}
        />
      </div>
    </div>
  );
};

export default memo(ImageComponent);
