import { SectionWrap } from "Components/Layouts";
import { classnames } from "Utils/Classnames.Util";
import { MAX_INNER_CONTENT_WIDTH } from "Constants/Common.Constant";
import { getBlogImageSize } from "Utils/Common.Util";

import TitleComponent from "./Title.Component";
import ImageComponent from "./Image.Component";

const Section1 = ({ data }: { data: any }) => {
  const imageUrls = getBlogImageSize(data);

  return (
    <SectionWrap
      containerProps={{
        className: "flex justify-center bg-clever-primary py-8",
      }}
      className="flex flex-col items-center"
    >
      <div
        className={classnames(
          "flex flex-col-reverse gap-5 md:gap-7 lg:flex-row",
        )}
        style={{ maxWidth: MAX_INNER_CONTENT_WIDTH }}
      >
        <TitleComponent date={data?.date} content={data?.title?.rendered} />
        <ImageComponent
          url={imageUrls.original || imageUrls.medium}
          title={data?.title?.rendered}
        />
      </div>
    </SectionWrap>
  );
};

export default Section1;
