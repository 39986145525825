import { useEffect, useMemo, useState } from "react";

export const useIsInViewport = (ref: any) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const options = useMemo(
    () => ({
      root: null,
      rootMargin: "0px",
      threshold: 0.6,
    }),
    []
  );

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIsIntersecting(entry.isIntersecting),
      options
    );

    !!ref && !!ref.current && observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [options, ref]);

  return isIntersecting;
};
