import { memo, useEffect, useRef, useState } from "react";

import {
  AppDownLoadButton,
  LottieImage,
  RotateHighlightText,
  Typography,
} from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";
import { useIsInViewport } from "Hooks/useIsInViewPort";
import { _debounce } from "Utils/Lodash.Util";

// import FlowerMobile from "Assets/Images/Others/flower_mobile.png";
import FlowerMobile from "Assets/Lotties/flower_mobile.json";

const TitleComponent = () => {
  const ref = useRef<any>(null);
  const lottieRef = useRef<any>(null);

  const isInViewport = useIsInViewport(ref);

  const [isPaused, setIsPaused] = useState(true);
  const [isAlreadyPlay, setIsAlreadyPlay] = useState(false);

  useEffect(() => {
    if (isInViewport && isPaused && !isAlreadyPlay) {
      setIsPaused(false);
      debouncePlayed();
      lottieRef && lottieRef.current && lottieRef.current?.play();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAlreadyPlay, isInViewport, isPaused]);

  const debouncePlayed = _debounce(() => {
    setIsPaused(true);
    setIsAlreadyPlay(true);
  }, 1000);

  return (
    <div
      ref={ref}
      className="flex flex-col gap-12 lg:gap-5 lg:flex-row flex-1 justify-between items-center bg-clever-link px-10 py-10 md:py-20 text-white rounded-[40px] relative overflow-hidden"
    >
      <div className="z-10">
        <Typography.SectionTitle className="mb-4 text-center">
          <RotateHighlightText
            content={localesText("common.shop")}
            className="mr-2 inline-block max-w-[200px]"
          />
          <span className="block lg:inline">
            {localesText("in_store.clever_today")}
          </span>
        </Typography.SectionTitle>
        <Typography.Content className="text-center">
          {localesText("in_store.download_the_app")}
        </Typography.Content>
      </div>
      <AppDownLoadButton
        showSeperate
        theme="dark"
        className="z-10 mb-32 sm:mb-40 lg:mb-0"
      />
      <div className="mt-[230px] sm:mt-0 lg:-right-20 lg:-top-2/3 sm:top-1/3 absolute md:max-w-[650px]">
        <LottieImage
          lottieRef={lottieRef}
          options={{ animationData: FlowerMobile }}
          useObserver={false}
        />
      </div>
    </div>
  );
};

export default memo(TitleComponent);
