import { memo } from "react";

import { NormalImage } from "Components/Lib";

import ReferCustomer from "Assets/Images/Others/xero_customer_one.png";

const ImageComponent = () => (
  <div className="flex flex-1 items-center justify-center ">
    <div className="relative w-full max-w-[530px]">
      {/* <LottieImage options={{ animationData: GetPaidLottie }} /> */}
      <NormalImage
        src={ReferCustomer.src}
        width={540}
        height={540}
        loading="lazy"
      />
    </div>
  </div>
);

export default memo(ImageComponent);
