import { RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";
import { useExpand } from "Hooks/useExpand";

import Collapses from "./Collapses";

export const INDUSTRIES = [
  {
    id: 1,
    title: localesText("payto.recurring_payments"),
    content: localesText("payto.recurring_payments_description"),
  },
  {
    id: 2,
    title: localesText("payto.one_off"),
    content: localesText("payto.one_off_description"),
  },
];

const TitleComponent = () => {
  const { expandingId, onToggle } = useExpand(1);

  return (
    <div className="flex flex-1 flex-col pl-8 lg:pl-8">
      <div className="mb-6 text-center sm:text-left ">
        <Typography.SectionTitle>
          {localesText("payto.in_real_time")}{" "}
          <RotateHighlightText content={localesText("payto.real_time")} />
        </Typography.SectionTitle>
      </div>

      <Collapses
        expandingId={expandingId}
        onToggle={onToggle}
        data={INDUSTRIES}
      />
    </div>
  );
};

export default TitleComponent;
