import { SectionWrap } from "Components/Layouts";

import TitleComponent from "./Title.Component";

const Section6 = () => {
  return (
    <SectionWrap
      contentContainerProps={{
        className: "flex flex-col gap-10 md:gap-6 md:flex-row",
      }}
      className="flex items-center justify-center"
    >
      <TitleComponent />
    </SectionWrap>
  );
};

export default Section6;
