import { memo } from "react";

import { Button, RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";
import { _toLower } from "Utils/Lodash.Util";
import { NextLink } from "Components/Lib/Next";
import { PATHS_NAME } from "Constants/PathName.Constant";
import { SectionWrap } from "Components/Layouts";

const DoBusiness = () => {
  return (
    <SectionWrap
      contentContainerProps={{
        className: "flex flex-col gap-6 md:flex-row",
      }}
      className="flex items-center justify-center"
    >
      <div className="flex max-w-[960px] flex-1 flex-col text-center">
        <Typography.SectionTitle className="mb-2">
          {localesText("payto.payto_is_the")}{" "}
          <RotateHighlightText
            content={_toLower(localesText("common.clever"))}
          />{" "}
          {localesText("payto.way_to_do")}
        </Typography.SectionTitle>

        <Typography.Body className="mb-6 text-clever-description md:mb-12">
          {localesText("payto.ready_to_transform")}
        </Typography.Body>

        <NextLink href={PATHS_NAME.GET_IN_TOUCH}>
          <Button
            buttonSize="large"
            className="mb-8 rounded-full md:mb-12"
            wide
            buttonType="primary"
          >
            {localesText("common.get_in_touch")}
          </Button>
        </NextLink>
      </div>
    </SectionWrap>
  );
};

export default memo(DoBusiness);
