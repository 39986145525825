import { NextLink } from "Components/Lib/Next";

import { ExpandButton, Typography } from "Components/Own";
import { PATHS_NAME } from "Constants/PathName.Constant";
import { classnames } from "Utils/Classnames.Util";

const CollapseComponent = ({
  index,
  lastIndex,
  id,
  title,
  content,
  second_content,
  link_content,
  highlight_content,
  isExpanding,
  onToggle,
}: {
  index: number;
  lastIndex?: number;
  id: number;
  title: string;
  highlight_content?: string;
  content: string;
  second_content?: string;
  link_content?: string;
  isExpanding: boolean;
  onToggle: (id: number) => void;
}) => {
  return (
    <div
      tabIndex={index}
      className={classnames("collapse border-b-base-300", {
        "collapse-close": !isExpanding,
        "border-b-[1px]": index !== lastIndex,
      })}
    >
      <input
        type="checkbox"
        className="cursor-pointer"
        checked={isExpanding}
        onChange={(event) => onToggle(id)}
      />

      <div className="collapse-title px-0 py-6">
        <div className="flex items-center justify-between gap-4 overflow-hidden">
          <Typography.Heading2
            className="flex-1 text-left"
            onClick={(event) => onToggle(id)}
          >
            {title}
          </Typography.Heading2>
          <ExpandButton
            inputProps={{
              checked: isExpanding,
              onChange: (event) => {
                onToggle(id);
              },
            }}
          />
        </div>
      </div>
      <div tabIndex={index} className="collapse-content pl-0">
        <Typography.Body className="py-4 text-clever-description">
          {content}

          {highlight_content ? (
            <strong className="text-clever-link">{highlight_content}</strong>
          ) : (
            ""
          )}
        </Typography.Body>
        {second_content && (
          <Typography.Body className="text-clever-description">
            {second_content}
            <NextLink href={PATHS_NAME.PAYTO} className="text-clever-link">
              {link_content}
            </NextLink>
          </Typography.Body>
        )}
      </div>
    </div>
  );
};

export default CollapseComponent;
