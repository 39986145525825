import React, { memo } from "react";
import { useHubspotForm } from "next-hubspot";
import ContentLoader from "react-content-loader";

import { _isEmpty } from "Utils/Lodash.Util";

const HubspotForm = ({
  portalId,
  formId,
}: {
  portalId?: string;
  formId?: string;
}) => {
  const { loaded, formCreated } = useHubspotForm({
    portalId:
      portalId ?? `${process.env.NEXT_PUBLIC_HUBSPOT_CONTACT_PORTAL_ID}`,
    formId: formId ?? `${process.env.NEXT_PUBLIC_HUBSPOT_CONTACT_FORM_ID}`,
    target: "#contact-us-form",
  });

  const [hideLoading, setHideLoading] = React.useState(false);

  React.useEffect(() => {
    let timer: any = null;
    if (loaded) {
      timer = setTimeout(() => {
        setHideLoading(true);
      }, 300);
    }
    return () => clearTimeout(timer);
  }, [loaded]);

  return (
    <div className="flex min-h-[12rem] w-[95%] sm:w-[85%] flex-1 flex-col rounded-[24px] bg-white p-[40px] px-[40px] shadow">
      {formCreated && <div className="min-h-[5rem]" id="contact-us-form" />}
      {!hideLoading && (
        <ContentLoader
          speed={2}
          width={"100%"}
          height={"100%"}
          viewBox="0 0 400 220"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="25" y="0" rx="5" ry="5" width="87%" height="20" />
          <rect x="25" y="30" rx="5" ry="5" width="87%" height="20" />
          <rect x="25" y="60" rx="5" ry="5" width="87%" height="20" />
          <rect x="25" y="90" rx="5" ry="5" width="87%" height="20" />
          <rect x="25" y="120" rx="5" ry="5" width="87%" height="20" />
          <rect x="25" y="150" rx="5" ry="5" width="87%" height="20" />
          <rect x="33%" y="190" rx="5" ry="5" width="30%" height="20" />
        </ContentLoader>
      )}
    </div>
  );
};

export default memo(HubspotForm);
