import React from "react";

import { RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

export default function TitleComponent() {
  return (
    <div className="flex flex-col items-center max-w-[780px] text-white text-center">
      <Typography.Giant className="mb-6">
        {localesText("in_store.earn_instant_cashback")}
        <RotateHighlightText
          content={localesText("in_store.in_store")}
          className="ml-3"
        />
      </Typography.Giant>
      <Typography.Content>
        {localesText("in_store.supercharge_savings")}
      </Typography.Content>
    </div>
  );
}
