import { useEffect, useState } from "react";

import { AppDownLoadButton, Typography } from "Components/Own";
import { getStoresCount } from "Services";
import { localesText } from "Utils/LocalesText.Util";
import { formatNumber } from "Utils/Number.Util";

const TitleComponent = () => {
  const [storeCount, setStoreCount] = useState<number>(0);

  useEffect(() => {
    const fetchStoreCount = async () => {
      const response = await getStoresCount();
      setStoreCount(response.storeCount);
    };
    fetchStoreCount();
  }, []);

  return (
    <div className="flex flex-1 flex-col pb-10 pt-10">
      <div className="mb-5">
        <Typography.Heading1>
          {localesText("shops.download_hello_clever_app")}
        </Typography.Heading1>
      </div>

      <Typography.Body className="mb-6 text-clever-description">
        {localesText("home.want_to_see_more")}{" "}
        <strong className="font-semibold text-clever-black">
          {formatNumber(storeCount)}+
        </strong>{" "}
        {localesText("home.offers_in_our_app")}
      </Typography.Body>

      <div className="flex justify-start">
        <AppDownLoadButton theme="dark" showSeperate />
      </div>
    </div>
  );
};

export default TitleComponent;
