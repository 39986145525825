import { SectionWrap } from "Components/Layouts";

import TitleComponent from "./Title.Component";

const Section1 = ({ storeCount }: { storeCount: number }) => {
  return (
    <SectionWrap
      containerProps={{
        className: "flex justify-center bg-clever-primary py-16",
      }}
      contentContainerProps={{
        style: { maxWidth: 1200 },
        className: "w-full",
      }}
      className="flex justify-center"
    >
      <TitleComponent storeCount={storeCount} />
    </SectionWrap>
  );
};

export default Section1;
