import { memo } from "react";

import { NormalImage } from "Components/Lib";
import { LottieImage } from "Components/Own";
import { classnames } from "Utils/Classnames.Util";

import ReferFriend from "Assets/Images/Others/payto_instant_customer.png";
import { LOTTIE_URLS } from "Constants/URL.Constant";

const ImageComponent = () => (
  <div className="flex flex-1 items-center justify-center ">
    <div className="relative w-full max-w-[530px]">
      <NormalImage src={ReferFriend.src} width={540} height={540} />
      <div
        className={classnames(
          "absolute bottom-[5%] left-0 right-0 mx-auto inline-block",
          "max-w-[390px] sm:max-w-[460px] md:max-w-[460px]"
        )}
      >
        <LottieImage
          height="auto"
          width="auto"
          options={{
            animationData: "",
            url: LOTTIE_URLS.PAYTO_INSTANT,
          }}
        />
      </div>
    </div>
  </div>
);

export default memo(ImageComponent);
