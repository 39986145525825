import { memo } from "react";

import { localesText } from "Utils/LocalesText.Util";
import { RotateHighlightText, Typography } from "Components/Own";

const TitleComponent = () => {
  return (
    <div className="flex flex-1 flex-col justify-center">
      <div className="mb-6 text-left">
        <Typography.SectionTitle>
          {localesText("tyro.be")}
          <RotateHighlightText
            content={localesText("tyro.discovered")}
            className="ml-3 inline-block"
          />
          <br />
          {localesText("tyro.by_new_customers")}
        </Typography.SectionTitle>
      </div>

      <Typography.Body className=" text-clever-description sm:pr-4 md:pr-11">
        {localesText("tyro.get_your_business")}
      </Typography.Body>
    </div>
  );
};

export default memo(TitleComponent);
