import { WrapList } from "Components/Own";

import Platform from "./Platform.Component";

import shopify from "Assets/Images/Brands/shopify.png";
import magento from "Assets/Images/Brands/magento.png";
import woo from "Assets/Images/Brands/woo.png";
import opencart from "Assets/Images/Brands/opencart.png";
import bigcommerce from "Assets/Images/Brands/bigcommerce.png";

const PlatFormList = () => {
  const PlatForms = [
    {
      id: 1,
      image: shopify,
      alt: "shopify",
      width: 113,
      link: "https://apps.shopify.com/cleverpay",
    },
    {
      id: 2,
      image: magento,
      alt: "magento",
      width: 132,
      link: "https://marketplace.magento.com/cleverpay-payment-gateway.html",
    },
    {
      id: 3,
      image: woo,
      alt: "woo",
      width: 67,
      link: "https://wordpress.org/plugins/cleverpay-gateway/",
    },
    {
      id: 4,
      image: opencart,
      alt: "opencart",
      width: 134,
      link: "https://www.opencart.com/index.php?route=marketplace/extension/info&extension_id=43234",
    },
    {
      id: 5,
      image: bigcommerce,
      alt: "bigcommerce",
      width: 141,
      isCommingSoon: true,
    },
  ];

  return (
    <WrapList
      data={PlatForms}
      ItemComponent={Platform}
      itemSize={256}
      needFill={false}
    />
  );
};

export default PlatFormList;
