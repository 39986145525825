import { RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

export default function TitleComponent() {
  return (
    <div className="flex flex-col justify-center items-center gap-2">
      <Typography.Body className="uppercase font-semibold">
        {localesText("tyro.the_advantages")}
      </Typography.Body>
      <Typography.SectionTitle className="text-center max-w-[800px]">
        {localesText("tyro.why_opt")}

        <RotateHighlightText
          content={localesText("tyro.loyalty")}
          className="mx-2"
        />
        {localesText("tyro.offering")}
      </Typography.SectionTitle>
    </div>
  );
}
