import { WrapList } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

import Instant from "./Instant.Component";

export default function InstantList() {
  const data = [
    {
      id: 1,
      image: "Assets/Svg/register.svg",
      content: localesText("tyro.instant_cashback_text_one"),
    },
    {
      id: 2,
      image: "Assets/Svg/virtual_card.svg",
      content: localesText("tyro.instant_cashback_text_two"),
    },
    {
      id: 3,
      image: "Assets/Svg/lightning_green.svg",
      content: localesText("tyro.instant_cashback_text_three"),
    },
  ];

  return (
    <WrapList
      data={data}
      ItemComponent={Instant}
      itemSize={440}
      fillNumber={0}
      shoulScale={false}
      contentContainerClassName="flex-col md:flex-row gap-x-10"
    />
  );
}
