import { WrapList } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

import Benefit from "./Benefit.Component";

export default function BenefitsList() {
  const data = [
    {
      id: 1,
      image: "Assets/Svg/lightning_blue.svg",
      title: localesText("in_store.real_cash"),
      content: localesText("in_store.say_goodbye"),
    },
    {
      id: 2,
      image: "Assets/Svg/star_slash.svg",
      title: localesText("in_store.pointless_points"),
      content: localesText("in_store.skip_frustration"),
    },
    {
      id: 3,
      image: "Assets/Svg/virtual_card_green.svg",
      alt: "benefit",
      title: localesText("in_store.tap_and_pay"),
      content: localesText("in_store.simply_tap"),
    },
  ];

  return (
    <WrapList
      data={data}
      ItemComponent={Benefit}
      itemSize={440}
      fillNumber={0}
      shoulScale={false}
      contentContainerClassName="gap-y-6"
    />
  );
}
