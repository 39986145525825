import { RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

export default function TitleComponent() {
  return (
    <div>
      <Typography.SectionTitle className="text-center">
        <RotateHighlightText
          content={localesText("in_store.how")}
          className="ml-2 inline-block"
        />
        {localesText("in_store.it_works")}
      </Typography.SectionTitle>
    </div>
  );
}
