import { SectionWrap } from "Components/Layouts";

import ImageComponent from "./Image.Component";
import InstantCashbackList from "./InstantCashback";
import TitleComponent from "./Title.Component";

const Section9 = () => {
  return (
    <SectionWrap
      contentContainerProps={{
        className: "flex flex-col gap-10 flex-1",
      }}
      className="flex items-center justify-center"
    >
      <TitleComponent />
      <div className="flex flex-col lg:flex-row items-start">
        <ImageComponent />
        <InstantCashbackList />
      </div>
    </SectionWrap>
  );
};

export default Section9;
