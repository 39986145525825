import { AppDownLoadButton, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";
import { formatNumber } from "Utils/Number.Util";

const TitleComponent = ({ storeCount }: { storeCount: number }) => {
  return (
    <div className="flex-1 pb-0 lg:pb-12">
      <div>
        <Typography.Heading2 className="mb-[20px]">
          {localesText("shops.want_more_deals")}
        </Typography.Heading2>
        <Typography.Content
          className="text-clever-description"
          dangerouslySetInnerHTML={{
            __html: localesText("shops.got_offer_in_app", {
              count: formatNumber(storeCount),
            }),
          }}
        />
      </div>
      <AppDownLoadButton
        theme="dark"
        showSeperate
        important={false}
        className="mt-5 flex flex-wrap items-center justify-start gap-4"
      />
    </div>
  );
};

export default TitleComponent;
