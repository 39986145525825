import { RotateHighlightText, Typography } from "Components/Own";
import { localesText } from "Utils/LocalesText.Util";

const TitleComponent = () => {
  return (
    <div className="flex flex-1 flex-col justify-center">
      <div className="mb-6 text-left">
        <Typography.SectionTitle>
          <RotateHighlightText content={localesText("payto.instant")} />{" "}
          {localesText("payto.customer_verification")}
        </Typography.SectionTitle>
      </div>

      <Typography.Body className="px-2 text-clever-description sm:pr-4 md:px-0 md:pr-11">
        {localesText("payto.customer_verification_description_1")}
      </Typography.Body>
    </div>
  );
};

export default TitleComponent;
