import { memo } from "react";

import { SectionWrap, SlateWhite } from "Components/Layouts";

import Title from "./Title";
import ImageComponent from "./Image.Component";

const AccountToAccount = () => {
  return (
    <SectionWrap
      containerProps={{ className: "my-8 md:-mt-4 -mt-10" }}
      className="flex items-center justify-center"
      contentContainerProps={{ className: "flex flex-col w-full" }}
    >
      <SlateWhite
        containerClassName="pr-8 py-12 min-h-[750px]"
        contentContainerClassName="w-full flex flex-col-reverse items-center lg:flex-row gap-6"
        firstComponent={<ImageComponent />}
        secondComponent={<Title />}
      />
    </SectionWrap>
  );
};

export default memo(AccountToAccount);
