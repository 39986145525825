import React from "react";

import { SectionWrap } from "Components/Layouts";

import TitleComponent from "./Title.Component";
import ImageComponent from "./Image.Component";

export default function Section3() {
  return (
    <SectionWrap
      contentContainerProps={{
        className: "flex flex-col-reverse gap-10 md:gap-6 md:flex-row",
      }}
      className="flex items-center justify-center"
    >
      <TitleComponent />
      <ImageComponent />
    </SectionWrap>
  );
}
