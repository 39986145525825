import { HorizontalList, OfferItem } from "Components/Own";
import { InstantDealProps } from "Types";

const OfferListComponent = ({
  instantDeals,
}: {
  instantDeals: InstantDealProps[];
}) => {
  return (
    <HorizontalList
      containerClassName="my-8 px-[4%] sm:px-[3%] md:px-[2%]"
      data={instantDeals}
      ItemComponent={OfferItem}
      itemSize={256}
    />
  );
};

export default OfferListComponent;
